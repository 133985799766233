import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Box, Typography, useTheme } from '@mui/material';
import isHotkey from 'is-hotkey'
import escapeHtml from 'escape-html'
import { Editable, withReact, useSlate, Slate, useFocused } from 'slate-react'
import {
  Text,
  Editor,
  Transforms,
  createEditor,
  Descendant,
  Element as SlateElement,
} from 'slate'
import { withHistory } from 'slate-history'
import { Button, Icon, Toolbar } from './RichTextComponents'
import { slateToHtml } from '@slate-serializers/html';
import { InputText } from './inputText';


const HOTKEYS: { [key: string]: string } = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
}

const LIST_TYPES = ['ol', 'ul']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const RichText = ({
  placeholder = '',
  value,
  setValue,
  onFocusEditor,
  onBlurEditor,
  isReady,
  isMultiline,
}: any) => {
  const theme = useTheme();
  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])
  const editor = useMemo(() => withHistory(withReact(createEditor())), [])
  const [show, setShow] = useState(false);
  const focused = useFocused();

  useEffect(() => {
    if (isReady) {
      setShow(true);
    }
  }, [isReady]);

  const handleOnChange = (value: any) => {
    setValue(value)
  }

  return (
    <>
      {show && <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderRadius: '10px',
        minHeight: isMultiline ? '100px' : '400px',
        border: '1px solid #CCCCCC',
        '& .editor': {
          padding: '0px 20px 8px',
          pt: '10px',
          minHeight: isMultiline ? '100px' : '400px',
          height: '100%',
          border: `1px solid transparent`,
        },
        '& .editor:focus': {
          outline: 'none',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px'
        }
      }}>
        <Slate editor={editor} initialValue={value} onChange={handleOnChange}>
          <Box className='toolbar-box' sx={[
            {
              width: '100%',
              border: '1px solid #F1F1F1',
              boxSizing: 'border-box',
              padding: '4px 0px',
              backgroundColor: '#fff',
              position: 'absolute',
              zIndex: '5',
              top: '-35px',
              '& .toolbar': {
                p: '0',
                m: '0',
                border: 'none',
                flexWrap: 'wrap',
              },
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                top: '-75px',
              }
            }),
          ]}>
            <Toolbar className='toolbar'>
              <MarkButton format="bold" icon="format_bold" />
              <MarkButton format="italic" icon="format_italic" />
              <MarkButton format="underline" icon="format_underlined" />
              <MarkButton format="code" icon="code" />
              <BlockButton format="h1" icon="looks_one" />
              <BlockButton format="h2" icon="looks_two" />
              <BlockButton format="blockquote" icon="format_quote" />
              <BlockButton format="ol" icon="format_list_numbered" />
              <BlockButton format="ul" icon="format_list_bulleted" />
              <BlockButton format="left" icon="format_align_left" />
              <BlockButton format="center" icon="format_align_center" />
              <BlockButton format="right" icon="format_align_right" />
              <BlockButton format="justify" icon="format_align_justify" />
            </Toolbar>
          </Box>
          <Editable
            className='editor'
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            spellCheck
            autoFocus={false}
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault()
                  const mark = HOTKEYS[hotkey]
                  toggleMark(editor, mark)
                }
              }
            }}
            onFocus={onFocusEditor}
            onBlur={onBlurEditor}
          />
        </Slate>
      </Box>}
    </>
  )
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      //@ts-ignore
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      //@ts-ignore
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      //@ts-ignore
      type: isActive ? 'paragraph' : isList ? 'li' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        //@ts-ignore
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor: any, format: any) => {
  const marks = Editor.marks(editor)
  //@ts-ignore
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element }: any) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'blockquote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'ul':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'h1':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'li':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'ol':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleBlock(editor, format)
        //@ts-ignore
        window.getSelection().removeAllRanges();
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault()
        toggleMark(editor, format)
        //@ts-ignore
        window.getSelection().removeAllRanges();
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  )
}

export default RichText