import { Box, SvgIcon, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { month } from './../data/data';
import { useTheme } from '@mui/material/styles';
import { updateMatchingById } from '../service/matchingService';
import ReactGA from "react-ga4";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

interface VendorPostProps {
  post: any;
}

const VendorPost: React.FC<VendorPostProps> = ({ post }) => {
  const navigate = useNavigate();
  const data = post?.requestedVehicleItem || post;
  const date = new Date(Date.parse(data?.createdAt));
  const theme = useTheme();
  const [bookmarkPost, setBookmarkPost] = useState<'GENERAL' | 'FAVORITE' | 'NOT_INTERESTED'>(post?.bookmark || post?.matching?.bookmark);
  const [isFavorite, setIsFavorite] = useState<boolean>(post?.favorite || post?.matching?.favorite);
  const [isNotInterested, setIsNotInterested] = useState<boolean>(post?.bookmark === 'NOT_INTERESTED' || post?.matching?.bookmark === 'NOT_INTERESTED');

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    data && data?.id && navigate(`/vendor/${data?.id}`)
  };

  const handleFavorite = async () => {
    await updateMatchingById({ id: data?.id, bookmark: !isFavorite ? "FAVORITE" : "GENERAL" });

    ReactGA.event('favoritePost', {
      idPost: data?.inventoryId,
      requestedVehicleItemId: data?.requestedVehicleItemId,
    });
    setIsFavorite(!isFavorite);
    navigate(0);
  }

  const handleNotInterested = async () => {
    await updateMatchingById({ id: data?.id, bookmark: !isNotInterested ? "NOT_INTERESTED" : "GENERAL" });

    ReactGA.event('NotInterestedPost', {
      idPost: data?.inventoryId,
      requestedVehicleItemId: data?.requestedVehicleItemId,
    });
    setIsNotInterested(!isNotInterested);
    navigate(0);
  }

  return <>
    <Box sx={[{
      background: '#fff',
      padding: '20px 40px',
      paddingRight: '20px',
      borderRadius: '12px',
      lineHeight: '33.6px',
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    (theme) => ({
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      }
    }),
    ]} onClick={handleClick}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          variant="h6"
          sx={[{
            color: '#000',
            fontWeight: 600,
            fontSize: '21px',
            mb: '20px',
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              mb: '10px'
            }
          }),
          ]}
        >
          {data?.name}
        </Typography>

        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Tooltip title={!isNotInterested ? "Add to not interested" : "Remove from not interested"}>
            <SvgIcon
              sx={{
                fontSize: 40,
                cursor: 'pointer',
                '&:hover path, &:hover circle': {
                  stroke: !isNotInterested ? '#EB5757' : '#CCCCCC'
                }
              }}>
              {!isNotInterested ?
                <svg onClick={(e) => {
                  e.stopPropagation();
                  handleNotInterested();
                }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" fill="white" />
                  <path d="M33.4326 20C32.3087 22.0612 30.8264 23.898 28.7533 25.2368C26.6045 26.6244 23.7855 27.5 20 27.5C16.2145 27.5 13.3955 26.6244 11.2467 25.2368C9.17358 23.898 7.69128 22.0612 6.56743 20C7.69128 17.9388 9.17358 16.102 11.2467 14.7632C13.3955 13.3756 16.2145 12.5 20 12.5C23.7855 12.5 26.6045 13.3756 28.7533 14.7632C30.8264 16.102 32.3087 17.9388 33.4326 20Z" stroke="#CCCCCC" />
                  <circle cx="20" cy="20" r="4.5" stroke="#CCCCCC" />
                  <path d="M11 11L29 29" stroke="#CCCCCC" />
                </svg>
                :
                <svg onClick={(e) => {
                  e.stopPropagation();
                  handleNotInterested();
                }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" fill="white" />
                  <path d="M33.4326 20C32.3087 22.0612 30.8264 23.898 28.7533 25.2368C26.6045 26.6244 23.7855 27.5 20 27.5C16.2145 27.5 13.3955 26.6244 11.2467 25.2368C9.17358 23.898 7.69128 22.0612 6.56743 20C7.69128 17.9388 9.17358 16.102 11.2467 14.7632C13.3955 13.3756 16.2145 12.5 20 12.5C23.7855 12.5 26.6045 13.3756 28.7533 14.7632C30.8264 16.102 32.3087 17.9388 33.4326 20Z" stroke="#EB5757" />
                  <circle cx="20" cy="20" r="4.5" stroke="#EB5757" />
                  <path d="M11 11L29 29" stroke="#EB5757" />
                </svg>
              }
            </SvgIcon>
          </Tooltip>
          <Tooltip title={!isFavorite ? "Add to favorites" : "Remove from favorites"}>
            <SvgIcon
              sx={{
                fontSize: 40,
                cursor: 'pointer',
                '&:hover': {
                  fill: !isFavorite ? '#FED701' : 'none'
                },
                '&:hover path': {
                  stroke: !isFavorite ? '#FED701' : '#666666'
                }
              }}>
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavorite();
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill={!isFavorite ? "none" : "#FED701"}
              >
                <path d="M20 8.32961L22.9218 15.5289L23.0388 15.8174L23.3494 15.8396L31.0992 16.3937L25.1551 21.3971L24.9169 21.5976L24.9918 21.8998L26.8597 29.4415L20.2643 25.3346L20 25.17L19.7357 25.3346L13.1403 29.4415L15.0082 21.8998L15.0831 21.5976L14.8449 21.3971L8.9008 16.3937L16.6506 15.8396L16.9612 15.8174L17.0782 15.5289L20 8.32961Z" stroke={!isFavorite ? "#666666" : "#FED701"} />
              </svg>
            </SvgIcon>
          </Tooltip>
        </Box>
      </Box>

      <Box sx={[{
        mt: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }
      ]}>
        <Box sx={[{
          display: 'block',
        }
        ]}>
          <Typography
            sx={{
              color: '#666666',
              fontSize: '13px',
            }}
          >
            {`${date?.getMonth() + 1}-${date?.getDate()}-${String(date?.getFullYear()).slice(2)}`}
          </Typography>
          <Typography
            sx={{
              fontSize: '13px',
            }}
          >{!data?.user?.firstName ? 'Unknown' : `${data?.user?.firstName}`}</Typography>
        </Box>
        <Box>
          {data?.shippingType === "EVENT_PICKUP" && data?.eventName && <Box sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: '10px',
          }}>
            <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>Event: {data?.eventName}</Typography>
          </Box>}
          <Box sx={[{
            display: 'flex',
            alignItems: 'center'
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end'
            }
          }),
          ]}>
            {data?.shippingType === "LOCAL_PICKUP" || data?.shippingType === "EVENT_PICKUP" ?
              <>
                <Box sx={[
                  {
                    marginRight: '10px'
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      marginRight: '0px'
                    }
                  }),
                ]}>
                  <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>{data?.city}, {data?.state}</Typography>
                </Box>
                <Box sx={[
                  {
                    marginRight: '20px'
                  },
                  (theme) => ({
                    [theme.breakpoints.down('sm')]: {
                      marginRight: '10px'
                    }
                  }),
                ]}>
                  <Typography sx={{ color: '#008986' }}>+{data?.pickupRadius}mi</Typography>
                </Box>
              </>
              : <Box sx={[
                {
                  marginRight: '20px'
                },
                (theme) => ({
                  [theme.breakpoints.down('sm')]: {
                    marginRight: '0px'
                  }
                }),
              ]}>
                <Typography sx={{ fontSize: '13px', lineHeight: '21px', color: '#000000' }}>Ship from anywhere</Typography>
              </Box>
            }
            <Box sx={[{
              display: 'flex',
              marginRight: '0px'
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                marginRight: '20px'
              }
            }),
            ]}>
              <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M13.4038 18.9297C16.1469 14.7418 18 11.9126 18 8.66667C18 3.8802 13.9706 0 9 0C4.02944 0 0 3.8802 0 8.66667C0 11.9126 1.85313 14.7418 4.59619 18.9297C5.65598 20.5477 6.84861 22.3685 8.11852 24.4945C8.5146 25.1575 9.4854 25.1575 9.88147 24.4945C11.1514 22.3685 12.344 20.5477 13.4038 18.9297ZM9 13C11.7614 13 14 10.9853 14 8.5C14 6.01472 11.7614 4 9 4C6.23858 4 4 6.01472 4 8.5C4 10.9853 6.23858 13 9 13Z" fill="#CCCCCC" />
              </svg>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </>;
};

export default VendorPost;