import { getSubscriptions } from "../service/subscriptionService";

export const subscriptionAccess = async (userMode?: 'VENDOR' | 'BUYER' | 'ADMIN') => {
  if (userMode !== 'VENDOR') {
    return true;
  }

  try {
    const res = await getSubscriptions({ status: ['active', 'trialing'] });

    if (res?.data?.length) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('subscription error:', error);
    return false;
  }

}