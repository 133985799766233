import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { month } from './../data/data';
import { useTheme } from '@mui/material/styles';

interface BuyerPostProps {
  data: any;
}

const BuyerPost: React.FC<BuyerPostProps> = ({ data }) => {
  const navigate = useNavigate();
  const date = new Date(Date.parse(data?.createdAt));
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    data && data.id && navigate(`/buyer/${data.id}`)
  };

  return <>
    <Box sx={[{
      background: '#fff',
      padding: '20px 40px',
      borderRadius: '12px',
      lineHeight: '33.6px',
      cursor: 'pointer'
    },
    (theme) => ({
      [theme.breakpoints.down('sm')]: {
        padding: '20px',
      }
    }),
    ]}
      onClick={handleClick}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <Typography variant="h6" style={{ color: '#000', fontWeight: 600, fontSize: '21px', paddingBottom: '10px', }}>
          {data.name}
        </Typography>

        {data.shippingType === "EVENT_PICKUP" && data?.eventName && <Box sx={{
          ml: 'auto',
          mb: '10px',
        }}>
          <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>Event: {data?.eventName}</Typography>
        </Box>}
      </Box>

      <Box sx={[{
        display: 'flex',
        justifyContent: 'space-between'
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
        }
      }),
      ]}>
        <Box sx={[{
          display: 'flex',
          alignItems: 'center'
        },
        {
          mb: '20px'
        }
        ]}>
          <Box sx={{ display: 'flex', marginRight: '21px' }}>
            <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.4038 18.9297C16.1469 14.7418 18 11.9126 18 8.66667C18 3.8802 13.9706 0 9 0C4.02944 0 0 3.8802 0 8.66667C0 11.9126 1.85313 14.7418 4.59619 18.9297C5.65598 20.5477 6.84861 22.3685 8.11852 24.4945C8.5146 25.1575 9.4854 25.1575 9.88147 24.4945C11.1514 22.3685 12.344 20.5477 13.4038 18.9297ZM9 13C11.7614 13 14 10.9853 14 8.5C14 6.01472 11.7614 4 9 4C6.23858 4 4 6.01472 4 8.5C4 10.9853 6.23858 13 9 13Z" fill="#CCCCCC" />
            </svg>
          </Box>
          {data.shippingType === "LOCAL_PICKUP" || data.shippingType === "EVENT_PICKUP" ?
            <Box>
              <Box sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
                <Box sx={{ marginRight: '10px' }}>
                  <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>{data?.city}, {data?.state}</Typography>
                </Box>

                <Box>
                  <Typography sx={{ color: '#008986' }}>+{data.pickupRadius}mi</Typography>
                </Box>
              </Box>
            </Box>
            : <Box sx={{ marginRight: '10px' }}>
              <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>Ship from anywhere</Typography>
            </Box>}
        </Box>

        <Box>
          <Typography>{`${date.getMonth() + 1}-${date.getDate()}-${String(date.getFullYear()).slice(2)}`}</Typography>
        </Box>
      </Box>
    </Box>
  </>;
};

export default BuyerPost;