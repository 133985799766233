import { Box, Typography, Button, Dialog, DialogContentText, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { InputText } from './elements/inputText';
import { emailPattern } from '../utils/index';
import { getLoginNavigationPath } from "../utils/navigation";
import { loginAuth, resendEmail } from '../service/authService';
import { getUserData } from '../service/userService';
import { useResponseError } from '../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { STORAGE_AUTH_TOKEN } from '../constants/storage';
import { useAuth } from '../hooks/useAuth';
import { useCookies } from 'react-cookie';
import { useState } from 'react';
import { Loader } from './elements/Loader';
import { STORAGE_USER_CONFIRM_TOKEN } from '../constants/storage';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

type LoginFormFields = {
	email: string;
	password: string;
};

const AccountLogin = () => {
	const { login, setUser } = useAuth();
	const navigate = useNavigate();
	const theme = useTheme();
	const { responseError, setResponseError } = useResponseError(50000);
  	const [cookies, setCookie] = useCookies(["userType"]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showResendBtn, setShowResendBtn] = useState<boolean>(false);
	const [resendErrorMsg, setResendErrorMsg] = useState<string>('');
	const tokenCookies = localStorage.getItem(STORAGE_USER_CONFIRM_TOKEN) || '';
	const [isModalResentOpened, setIsModalResentOpened] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		formState: { errors, dirtyFields },
	} = useForm<LoginFormFields>({
		defaultValues: {
			email: '',
		},
		mode: "onChange",
	});

	const onSubmit = async (data: any) => {
		setIsLoading(true);
		setShowResendBtn(false);
		try {
			const res = await loginAuth({
				data
			});
			login(res.accessToken);
			const resUserData = await getUserData({
				token: res?.accessToken
			});
			localStorage.setItem(STORAGE_AUTH_TOKEN, res?.accessToken);

			if(resUserData){
				setUser(resUserData);
				if (resUserData?.roles.includes('ADMIN')) {
					navigate('/admin', {
						replace: true
					});
				} else {
					setCookie('userType', resUserData?.mode === 'VENDOR' || resUserData?.roles?.includes('VENDOR') ? 'VENDOR' : 'BUYER', { path: '/' });

					const pathToNavigate = await getLoginNavigationPath(resUserData.mode);
					navigate(pathToNavigate, {
						replace: true
					});
				}
				ReactGA.event('login', {
					userEmail: resUserData?.email
				});
				window.location.reload();
			}
		} catch (error: any) {
			if(error.message === "please verify your email") {
				setShowResendBtn(true);
			} else {
				setShowResendBtn(false);
			}
			setResponseError(error.message);
		}
		setIsLoading(false);
	}


	const handleResendSubmit = async () => {
		setResendErrorMsg('');
		if (tokenCookies) {
			try {
				await resendEmail({ token: tokenCookies });
				setIsModalResentOpened(true);
			} catch (error: any) {
				setResponseError(error.message);
				setResendErrorMsg(error.message);
			}
		} else {
			setShowResendBtn(false);
			setResponseError('Something is wrong with your confirm token');
			setResendErrorMsg('Something is wrong with your confirm token');
		}
	}

	return <>
		{ isLoading ? <Loader /> : null}
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" style={{
				fontWeight: '700',
				fontSize: '21px',
				color: '#000',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Login
			</Typography>
			<Box
				component="form"
				autoComplete="off"
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					'& > :not(style)': { my: 0.5, width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
				}}
			>
				<InputText
					label="Email address"
					variant="filled"
					inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
					error={!!errors['email']}
					helperText={errors['email'] ? 'Please enter a valid value' : ''}
				/>
				<InputText
					label="Password"
					variant="filled"
					type="password"
					inputProps={{
						...register("password", {
							required: true, 
							validate: {
								isValid: (value) => {
									return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
								}
							}
						})
					}}
					error={!!errors['password']}
					helperText={errors['password'] ? 'Please enter a valid value' : ''}
				/>
				<Box sx={{ textAlign: 'left', color: theme.palette.error.main, display: 'flex', flexWrap: 'wrap'}}>
					{responseError}
					{responseError && showResendBtn && (
						<>.
						<Button sx={{ 
							py: '1px', 
							px: 0,
							mx: '3px',
							textDecoration: 'underline', 
							color: theme.palette.error.main,
							textTransform: 'none',
							fontWeight: '400'
						}} onClick={handleResendSubmit} variant="text">Resend</Button>confirm message</>
					)}
				</Box>
				<Box sx={{ textAlign: 'center' }}>
					<Button sx={{ py: '1px', textDecoration: 'underline' }} onClick={() => navigate('../request-reset', { replace: true })} variant="text">Forgot password?</Button>
				</Box>
				<Box sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
					<Button type="submit" sx={{ py: '8px', borderRadius: '10px', width: '100%' }} variant="contained">Login</Button>
				</Box>
			</Box>
		</Box>
		<Dialog
			sx={[{
				'.MuiDialog-paper': {
					px: '55px',
					py: '10px',
					pt: '20px',
					maxWidth: '440px',
					boxSizing: 'border-box',
					width: '100%'
				}
			}, (theme) => ({
				[theme.breakpoints.down('md')]: {
					'.MuiDialog-paper': {
						px: '15px',
					}
				}
			})]
			}
			open={isModalResentOpened}
			onClose={() => setIsModalResentOpened(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CloseModal close={() => setIsModalResentOpened(false)} />
			<DialogTitle sx={{
				fontWeight: '700',
				textAlign: 'center'
			}}>
				{resendErrorMsg ? "Something went wrong" : `Request has been submitted successfully`}
			</DialogTitle>
			{!resendErrorMsg && <DialogContent>
				<DialogContentText sx={{
					fontWeight: '400',
					color: '#008986',
					mb: '5px',
					textAlign: 'center'
				}}>
					Please check your email. If you do not see the message in your inbox, please check spam.
				</DialogContentText>
			</DialogContent>}
		</Dialog>
	</>;
};

export default AccountLogin;