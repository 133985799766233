import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from './elements/inputText';
import { Box, Button, Container, Typography } from '@mui/material';

const AccountResetStep2 = () => {
  const navigate = useNavigate();

  return <>
		<Box sx={
			[{ 
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: { 
					px: '15px',
				}
			}),
		]}>
			<Typography variant="h4" style={{ 
					fontWeight: '700', 
					fontSize: '21px',
					color: '#000', 
					textAlign: 'center', 
					marginBottom: '18px', 
				}}>
				Your password has been changed. 
			</Typography> 

			<Box 
				sx={{
						'& > :not(style)': { my: 0.5, width: '100%' },
						'display': 'flex',
						'flexDirection': 'column',
						alignItems: 'center',
				}}
			>
				<Box sx={{ textAlign: 'center'}}>
					<Button 
						sx={{mt: '2px', py: '8px', borderRadius: '10px', width: '100%' }} 
						onClick={() => navigate('../login', { replace: true })} 
						variant="contained">Login
					</Button>
				</Box>
			</Box>
		</Box>
  </>;
};

export default AccountResetStep2;
