import React from 'react'
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from '../../hooks/useAuth';

const ProtectedAdminRoute = ({ children }: any) => {
  const { userType, isAdmin } = useAuth();
  const location = useLocation();

  const renderContent = () => {
    if (!isAdmin) {
      return <Navigate to={userType === 'VENDOR' ? '/vendor' : '/buyer'} state={{ from: location }} replace />
    }
    
    return children;
  }

  return renderContent();
};

export default ProtectedAdminRoute;