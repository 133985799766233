import { handleApiError } from "./handleApiError";
import instance, { baseURL } from './index';

type LoginProps = {
  data: {
    email: string;
    password: string;
  };
};

type VerifyProps = {
  data: {
    token: string;
    tokenRegister: string;
  }
}

type ResendProps = {
  data: {
    token: string;
  }
}

type PasswordResetProps = {
  data: {
    email: string | null;
  };
};

type RegisterProps = {
  data: {
    email: string;
    phoneNumber?: string;
    firstName: string;
    lastName: string;
    city?: string;
    state?: string;
    zip?: string;
    roles: "BUYER" | "VENDOR" | "BOTH";
    password: string;
    passwordConfirm?: string;
  };
};

type TResetPassword = {
  data: {
    token: string,
    userId: string,
    password: string,
    passwordConfirm: string
  }
}

type TPatchPassword = {
  data: {
    oldPassword: string,
    password: string,
    passwordConfirm: string,
  }
}

export const registerAuth = async ({ data }: any) => {
  try {
    const response = await instance.post(`/auth/register`, data,);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const loginAuth = async ({ data }: LoginProps) => {
  try {
    const response = await instance.post(`/auth/login`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const verifyAuth = async ({ data }: VerifyProps) => {
  try {
    const response = await instance.post(`/auth/verify`, {
      token: data.token
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const resendEmail = async ({ token }: any) => {
  try {
    const response = await instance.post(`/auth/resend/email-verification`, null, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};


export const requestPasswordResetAuth = async ({ data }: PasswordResetProps) => {
  try {
    await instance.post(`/auth/password-reset/request`, {
      email: data.email,
    });
  } catch (error: any) {
    handleApiError(error);
  }
};

export const passwordResetAuth = async ({ data }: TResetPassword) => {
  try {
    await instance.post(`/auth/password-reset`, data);
  } catch (error: any) {
    handleApiError(error);
  }
};

export const passwordPatch = async ({ data }: TPatchPassword) => {
  try {
    await instance.patch(`/auth/password`, data);
  } catch (error: any) {
    handleApiError(error);
  }
};

