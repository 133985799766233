import { Grid, Box, Typography } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import BuyerPost from './buyerPost';
import { useResponseError } from './../hooks/useResponseError';
import { getPosts } from './../service/postService';
import { STORAGE_AUTH_TOKEN } from '../constants/storage';
import { useTheme } from '@mui/material/styles';
import { Loader } from './elements/Loader';

const BuyerActive = () => {
  const token = localStorage.getItem(STORAGE_AUTH_TOKEN);
  const [posts, setPosts] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true);
  const { responseError, setResponseError } = useResponseError();
  const theme = useTheme();
  const lastPostRef = useRef<HTMLDivElement | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 75 &&
      !isLoading &&
      hasMore
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const getPostsArr = useCallback(async () => {
    if (!hasMore) return;

    setIsLoading(true);
    const data = { page: pageNumber, take: 8 };

    try {
      const res = await getPosts(data);
      const newPosts = res.data.filter((el: any) => el?.requestStatus !== 'ARCHIVED');

      if (posts.length) {
        setPosts((prevPosts: any) => [...prevPosts, ...newPosts]);
      } else {
        setPosts([...newPosts]);
      }
      setHasMore(newPosts.length > 0);
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [hasMore, pageNumber, setResponseError]);

  useEffect(() => {
    getPostsArr();

    return () => { };
  }, [pageNumber, getPostsArr]);

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const renderNoPostinfo = () => {

    if (isLoading) {
      return (<Loader />)
    }

    if(!isLoading && responseError) {
      return (
        <Box sx={{
          justifyContent: 'center',
          textAlign: 'center',
          color: theme.palette.error.main
        }}>
          {responseError}
        </Box>
      )
    }

    return (
      <Typography variant="h5">
        There are no posts, you can create them.
      </Typography>
    )
  }

  return (
    <Box sx={{
      'mb': '35px',
    }}>
      {isLoading ? <Loader /> : null}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {posts?.length > 0 ? posts.map((el: any, i: any) => {
          if (i === posts.length - 1) {
            return (
              <Grid key={el?.id} item xs={12} md={6} xl={6} ref={lastPostRef}>
                <BuyerPost data={el} />
              </Grid>
            );
          } else {
            return (
              <Grid key={el?.id} item xs={12} md={6} xl={6}>
                <BuyerPost data={el} />
              </Grid>
            );
          }

          return (
            <Grid key={el?.id} item xs={12} md={6} xl={6}>
              <BuyerPost data={el} />
            </Grid>
          )
        }) : <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '35px',
          width: '100%',
        }}>
          {renderNoPostinfo()}
        </Box>}
      </Grid>
    </Box>
  );
};

export default BuyerActive;

