import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box
      sx={[{ 
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 0',
        maxWidth: '670px',
        width: '100%'
      },
      (theme: any) => ({
          [theme.breakpoints.down('sm')]: {
            maxWidth: '90%',
            pt: 0
          }
        }),
      ]}
    >
      <Typography
        sx={[{
          color: '#000',
          fontFamily: 'Inter',
          fontSize: '34px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '120%',
          mb: '40px'
        }]}
        variant="h2"
      >
        Terms of Use
      </Typography>

      <Typography
        sx={[{
          color: '#666',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '160%'
        }]}
      >
      SWAPIT FAST LLC'S TERMS OF USESwapit Fast LLC’s ("Swapit Fast LLC" or the “Company”) operates the website at swapitfast.com and the SwapIt Fast mobile application (together, the “Service”). By using the Service, you agree to the following Terms of Use ("Terms"), which constitute an agreement between Swapit Fast LLC and you. We may change these Terms from time to time. You will always be able to view the version currently in force by clicking on a link on our site.Scope of These TermsThese Terms apply to your use of the Service. When you leave the Service and go to another website or mobile application, different terms may apply.When we refer to ourselves as "we" or "Swapit Fast LLC" or the “Company,” we mean our entire organization, including any affiliated or related entity or any contractor or agent working under our direction and control.Our InformationYou agree that any ideas or concepts that you provide to us through the Service or by e-mail, are not owned by you, and we are free to use such ideas or concepts without paying you or obtaining your consent.This Service and its contents are intended to comply with the laws and regulations of the United States of America. While you can access the Service from outside the United States of America, our server is located within the United States of America, and you agree to be bound by such laws and regulations.Concerning Your PrivacyAccess to the Service may be monitored by Swapit Fast LLC. If monitored, the requesting URLs, the machine originating the request, and the time of the request, are logged for access statistics and security purposes. Your use and access of the Service constitutes your consent to such monitoring. Please see Swapit Fast LLC's Privacy Policy for details on how information from the
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;