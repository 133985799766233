import { useState } from 'react';
import {
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AdminActiveUsers from '../components/adminActiveUsers';
import { InputText } from '../components/elements/inputText';
import { useResponseError } from './../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import { inviteAdmin } from '../service/userService';
import { CloseModal } from '../components/modal/CloseModal';

const Admin = () => {
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(['/admin', '/admin/stat']);
  const currentTab = routeMatch?.pattern?.path;
  const [open, setOpen] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const { responseError, setResponseError } = useResponseError(30000);
  const theme = useTheme();
  const [emailState, setEmailState] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInviteSuccess(false);
  };

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  const onSubmitInviteAdmin = async () => {
    const data = {
      email: emailState
    }
    try {
      const res = await inviteAdmin(data);
      const formatData = res.data;
      setInviteSuccess(true)
    } catch (error: any) {
      setResponseError(error.message);
    }
  }

  return <>
    <Box sx={[{
      'display': 'flex',
      'justifyContent': 'space-between',
      flexWrap: 'wrap',
      pt: '60px',
      pb: '11px'
    },
    (theme) => ({
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      }
    }),
    ]}>
      <Typography variant="h4" style={{ color: '#000' }}>
        Admin panel
      </Typography>

      <Box sx={[{
        'ml': 'auto',
        p: '10px',
        borderRadius: '10px',
        background: '#fff',
        boxShadow: "0px 10px 10px 0px #0089861A"
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          background: 'transparent',
          boxShadow: 'none',
          ml: 0,
          my: '10px',
          mt: '30px',
          px: 0
        }
      }),
      ]}>
        <Button onClick={handleClickOpen} variant="contained">Add admin</Button>
      </Box>
      <Dialog
        sx={[{
          textAlign: 'center',
          '.MuiDialog-paper': {
            position: 'relative',
            px: '55px',
            pt: '20px',
            paddingBottom: '35px',
            maxWidth: '440px',
            boxSizing: 'border-box'
          }
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            '.MuiDialog-paper': {
              px: '15px',
              maxWidth: '355px',
            }
          }
        }),
        ]}
        open={open}
        onClose={handleClose}
      >
        <CloseModal close={handleClose} />
        <DialogTitle sx={{
          px: '0',
          fontWeight: '700',
          fontSize: '21px'
        }}>{inviteSuccess ? 'Request has been submitted successfully' :'Add admin'}</DialogTitle>
        <DialogContent sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          px: '0',
          pb: '10px'
        }}>
          {inviteSuccess ?
            <DialogContentText sx={{
              mt: '30px',
              fontSize: '16px',
              textAlign: 'center',
              color: '#008986',
            }}>
              The invite has been sent successfully!
            </DialogContentText>
            :
            <>
              <InputText
                autoFocus
                label="Email"
                type="email"
                variant="filled"
                fullWidth
                value={emailState}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailState(event.target.value);
                }}
              />
              <Button
                fullWidth
                sx={{
                  mt: '10px'
                }}
                onClick={onSubmitInviteAdmin} variant="contained">
                Add admin and send invite
              </Button>
              <Box sx={{ mt: '10px', textAlign: 'center', color: theme.palette.error.main }}>{responseError}</Box>
              <DialogContentText sx={{
                mt: '30px',
                fontSize: '13px',
                textAlign: 'center',
                color: '#000000'
              }}>
                The new admin will receive an Email with a link to register. During registration, the user will create a password.
              </DialogContentText></>}
        </DialogContent>
      </Dialog>
    </Box>

    <Tabs
      sx={[{
        '.MuiTabs-flexContainer': {
          pl: 0
        }
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          '.MuiTabs-flexContainer': {
            pl: 0
          }
        }
      }),
      ]}
      value={currentTab}>
      <Tab label="Users" value="/admin" to="/admin" component={Link} />
      {/* <Tab label="Statistics" value="/admin/statistics" to="/admin/statistics" component={Link} /> */}
    </Tabs>

    {currentTab === '/admin' ? <AdminActiveUsers /> : null}

    <Outlet />
  </>;
};

export default Admin;