import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export const Footer = () => {
  const location = useLocation();
  
  return (
    <>
      <footer className="App-footer">
        
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: '10px'
          }}
        >
          <a
            href='mailto:contact@swapitfast.com'
            style={{ textDecoration: 'none' }}
          >
            <Typography
              sx={{
                color: '#008986',
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '160%',
              }}
            >
              contact@swapitfast.com
            </Typography>
          </a>
          <Link
            style={{
              textDecoration: 'none',
            }}
            to={"https://swapitfast.com/terms-of-use"}
            target="_blank"
          >
            <Typography
              sx={{
                color: '#666',
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '160%',
              }}
            >
              Terms of Use
            </Typography>
          </Link>
        </Container>
      </footer>
    </>
  );
}