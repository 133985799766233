import { isInventoryFilled } from "../service/inventoryService";

/**
 * Define where to navigate users after login/registration.
 * @param userType UserData.mode
 */
export const getLoginNavigationPath = async (userType: string) => {
  // we need to redirect Vendors without inventory description to the page where they can set it.
  let pathToNavigate;
  const isVendor = userType === 'VENDOR';
  if (isVendor) {
      const hasInventory = await isInventoryFilled();
      if (hasInventory) {
          pathToNavigate = '/vendor';
      } else {
          pathToNavigate = '/vendor/settings/profile';
      }
  } else {
      pathToNavigate = '/buyer';
  }

  return pathToNavigate;
}