import { handleApiError } from "./handleApiError";
import instance from './index';

type TGetUser = {
  token?: string,
};

type TGetUserById = {
  id: string,
};

type TUserData = {
  phoneNumber?: string,
  firstName: string,
  lastName: string,
  city: string,
  state: string,
  zip: string
}

type TUpdateData = {
  id: string,
  data: TUserData
}

type TToggleUserRole = {
  id: string,
  role: string
}

type TRemoveData = {
  id: string,
}

type TGetUsers = {
  page?: number,
  take?: number,
  role?: string,
  subscriptionStatus?: string,
  searchText?: string,
  orderBy?: string,
  order?: string,
}

type TInviteAdmin = {
  email: string
}

type TSuspendUser = {
  id: string;
  suspend: boolean;
}

export const getUsers = async ({
  page = 1,
  take = 12,
  role,
  subscriptionStatus,
  searchText,
  orderBy = 'email',
  order = 'desc',
}: TGetUsers) => {
  try {
    const response = await instance.get(`/users`, {
      params: {
        page,
        take,
        filters: {
          searchText,
          role,
          subscriptionStatus,
        },
        sorting: {
          orderBy,
          order,
        }
      }
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getUserData = async ({ token }: TGetUser) => {
  try {
    const response = await instance.get(`/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getuserById = async ({ id }: TGetUserById) => {
  try {
    const response = await instance.get(`/users/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const updateUserData = async ({ id, data }: TUpdateData) => {
  try {
    const response = await instance.patch(`/users/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const toggleUserRole = async ({ id, role }: TToggleUserRole) => {
  const data = {
    role: role
  }
  try {
    const response = await instance.patch(`/users/${id}/role/toggle`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const removeUser = async ({ id }: TRemoveData) => {
  try {
    const response = await instance.delete(`/users/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const inviteAdmin = async ({ email }: TInviteAdmin) => {
  const data = {
    email
  }
  try {
    const response = await instance.post(`/users/admin`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const suspendUserApi = async ({ id, suspend }: TSuspendUser) => {
  try {
    const response = await instance.patch(`/users/suspend/${id}?suspend=${suspend}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const switchRoleMode = async (mode: 'VENDOR' | 'BUYER', id: string) => {
  try {
    const response = await instance.patch(`/users/${id}/mode`, { mode });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
} 