import { handleApiError } from "./handleApiError";
import instance from './index';
import { htmlToSlate } from "@slate-serializers/html";

type PostInventory = {
  description: string,
  attendingEvents: string,
};

export const getInventory = async (ignoreAbsense: boolean = false) => {
  try {
    const response = await instance.get(`/inventory/my`, {
      validateStatus(status: number) {
        return (status >= 200 && status < 300)
            || (ignoreAbsense && status === 404);
      }
    });

    // handle 404 as a normal response
    if (ignoreAbsense && response.status === 404) {
      return null;
    }

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const postInventory = async ({ description, attendingEvents }: PostInventory) => {
  try {
    const response = await instance.post(`/inventory/my`, {
      description,
      attendingEvents
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const isInventoryFilled = async (): Promise<boolean> => {
  const inventory = await getInventory(true);

  const description = inventory && htmlToSlate(inventory.description || '');
  if (inventory && description) {
    for (let i = 0; i < description.length; i++) {
      if (description[i]?.children[0]?.text) {
        return true;
      }
    }
  }

  return false;
};
