import instance from ".";
import { handleApiError } from "./handleApiError";

// type TCreateCheckout = {
//   success_url
// }

export const createCheckoutSession = async ({ priceId }: any) => {
  const data = {
    priceId
  };

  try {
    const response = await instance.post(`/billing/checkout-session`, data);

    return response.data
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getSubscriptions = async ({
  page = 1,
  take = 12,
  status,
  orderBy = 'updatedAt',
  order = 'desc',
}: any) => {


  try {
    const statusQueryParams = (status || []).map((status: string) => `&filters[status][]=${status}`).join('');
    const response = await instance.get(`/subscriptions/my?page=${page}&take=${take}${statusQueryParams}`, {
      // params:{
        // orderBy,
        // order,
      // }
  });

    return response.data
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getCustomerPortal = async () => {

  try {
    const response = await instance.post(`/billing/portal-session`);

    return response.data

  } catch (error: any) {
    handleApiError(error);
  }
}

export const getPricingPlans = async () => {

  try {
    const response = await instance.get(`/billing/pricing-plans`);

    return response.data

  } catch (error: any) {
    handleApiError(error);
  }
}
