import { handleApiError } from "./handleApiError";
import instance from './index';

type TMatchingList = {
  page?: number,
  take?: number,
  orderBy?: string,
  order?: string,
  bookmark?: string,
  search?: string,
}

type TUpdateMatching = {
  id: string;
  favorite?: boolean;
  bookmark?: 'GENERAL' | 'FAVORITE' | 'NOT_INTERESTED';
}

export const getMatchingList = async ({
  page = 1,
  take = 12,
  orderBy = 'assignedAt',
  order = 'desc',
  bookmark = 'GENERAL',
  search,
}: TMatchingList) => {
  try {
    const response = await instance.get(`/item-matching/my?filters[bookmark]=${bookmark}`, {
      params: {
        page,
        take,
        // orderBy,
        // order,
        search
      }
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getMatchingById = async (id: string) => {
  try {
    const response = await instance.get(`/item-matching/my/${id}`);

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const updateMatchingById = async ({ id, favorite, bookmark }: TUpdateMatching) => {
  try {
    const response = await instance.patch(`/item-matching/my/${id}`, {
      favorite,
      bookmark
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
} 

export const changeMatchingById = async ({ id, favorite, bookmark }: TUpdateMatching) => {
  try {
    const response = await instance.post(`/item-matching/my/${id}`, {
      favorite,
      bookmark
    });

    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
} 