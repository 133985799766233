import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Layout from "./pages/Layout";
import Vendor from "./pages/Vendor";
import Buyer from "./pages/Buyer";
import BuyerSettings from './components/buyerSettings';
import VendorSettings from './components/vendorSettings';
import NoPage from "./pages/NoPage";
import './App.css';
import BuyerArchived from './components/buyerArchived';

import BuyerCreate from './components/buyerCreate';
import BuyerEdit from './components/buyerEdit';
import Account from './components/account';
import AccountRegister from './components/accountRegister';
import AccountConfirm from './components/accountConfirm';
import AccountLogin from './components/accountLogin';
import AccountRequestReset from './components/accountRequestReset';
import AccountReset from './components/accountReset';
import AccountResetStep2 from './components/accountResetStep2';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BuyerProfileSettings from './components/buyerProfileSettings';
import BuyerGeneralSettings from './components/buyerGeneralSettings';
import VendorGeneralSettings from './components/vendorGeneralSettings';
import VendorProfileSettings from './components/vendorProfileSettings';
import BuyerPasswordSettings from './components/buyerPasswordSettings';
import VendorPasswordSettings from './components/vendorPasswordSettings';
import Admin from './pages/Admin';

import { useCallback, useEffect, useState } from 'react';
import { ProtectedLayout } from './components/auth/ProtectedLayout';
import { AuthProvider } from './hooks/useAuth';
import Conversation from "./pages/Conversation";
import SingleMatching from "./pages/SingleMatching";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { STORAGE_CONVERSATION, STORAGE_SIGNATURE, STORAGE_USER } from "./constants/storage";
import { CookiesProvider, useCookies } from "react-cookie";
import ProtectedAdminRoute from './components/auth/ProtectedAdminRoute';
import { TSignature, TUserProfile } from "./types/types";
import { talkJsId } from "./constants";
import Talk from "talkjs";
import { getSignature } from "./service/conversationService";
import { Session } from "talkjs/all";
import { getChatUserId } from "./utils/conversation";
import ReactGA from "react-ga4";

if (process.env.REACT_APP_ENV === 'prod') {
  ReactGA.initialize("G-Y7FNVHYBE1");
}

function App() {
  const cookieExpiration = new Date(new Date().getTime() + 24 * 60 * 60 * 1000); // tomorrow
  const [cookies] = useCookies(["userType"]);
  const userType = cookies.userType;
  const [user, setUser] = useState<TUserProfile | null>(JSON.parse(localStorage.getItem(STORAGE_USER) || 'null'));
  const [signature, setSignature] = useState<TSignature | null>(JSON.parse(sessionStorage.getItem(STORAGE_SIGNATURE) || "null"));
  const location = useLocation();

  const me = useCallback(() => {
    if (user) {
      return new Talk.User({
        id: getChatUserId(user?.id, user?.mode),
        name: user?.firstName,
        email: null,
        photoUrl: null,
        role: user?.mode === 'BUYER' ? 'Buyer' : 'Vendor',
      });
    } else {
      return new Talk.User({id: "string",name: "string",email: "string",photoUrl: null,role: "BUYER"});
    }
  }, [user]);
  
  const generateSignature = useCallback(async () => {
    try {
      const { signature } = await getSignature();
      const object = { code: String(signature), mode: user?.mode || '' };

      setSignature(object);
      sessionStorage.setItem(STORAGE_SIGNATURE, JSON.stringify(object));
    } catch (error) {
      console.error('signature error:', error);
    }
  }, [user]);

  useEffect(() => {
    if (localStorage && localStorage.getItem(STORAGE_USER)) {
      setUser(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
    }
  }, [location, location.pathname]);
  
  useEffect(() => {
    if (user && userType !== signature?.mode || (user?.roles.includes("ADMIN") && !signature)) {
      generateSignature();
    }
  }, [location, location.pathname, user, userType, signature, generateSignature]);

  const syncConversation = useCallback((session: Session) => {
    const data = sessionStorage.getItem(STORAGE_CONVERSATION);
    const cId = location.search?.split('Id=')[1];
    
    if (cId) {
      const conversation = session.getOrCreateConversation(cId);
      return conversation;
    }

    else if (data) {
      const { conversationId, conversationTitle, secondUser, currentPostId } = JSON.parse(data);

      if (user && signature) {
        const conversation = session.getOrCreateConversation(conversationId);
        const otherUser = new Talk.User(secondUser);
        
        conversation.setParticipant(session.me);
        if (secondUser) {
          conversation.setParticipant(otherUser);
        }
  
        conversation.setAttributes({ custom: {
          value: conversationTitle,
          postId: currentPostId,
          user1: JSON.stringify(session.me),
          user2: JSON.stringify(secondUser)
        } });

        return conversation;
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.id && !signature && location.pathname.includes('/conversations')) {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }, []);

  return (
    <ThemeProvider theme={user?.mode === 'VENDOR' || userType === 'VENDOR' ? vendorTheme : buyerTheme}>
      <CookiesProvider defaultSetOptions={{path: '/', expires: cookieExpiration /* tomorrow */ }}>
        <AuthProvider storageValue={user || null}>
          <Routes>
            <Route element={<ProtectedLayout signature={signature} talkJsId={talkJsId} me={me} />}>
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/buyer" />} />
                <Route path="conversations" element={<Conversation syncConversation={syncConversation} />} />
                <Route path="vendor/matches" element={<Vendor />} />
                <Route path="vendor/favorites" element={<Vendor />} />
                <Route path="vendor/not-interested" element={<Vendor />} />
                <Route path="vendor" element={user?.mode === 'VENDOR' ? <Vendor /> : <Navigate to="/buyer" />}>
                  <Route path="conversations" element={<Conversation syncConversation={syncConversation} />} />
                  <Route path="*" element={<Vendor />} />
                </Route>
                <Route path="vendor/:id" element={<SingleMatching />} />
                <Route path="vendor/settings" element={<VendorSettings />} >
                  <Route index element={<VendorGeneralSettings />} />
                  <Route path="general" element={<VendorGeneralSettings />} />
                  <Route path="profile" element={<VendorProfileSettings />} />
                  <Route path="password" element={<VendorPasswordSettings />} />
                </Route>
                <Route path="buyer" element={user?.mode === 'BUYER' ? <Buyer /> : <Navigate to="/vendor" />} >
                  <Route path="conversations" element={<Conversation />} />
                  {/* <Route path=":id" element={<BuyerPostPage />} /> */}
                  <Route path="archived" element={<BuyerArchived />} />
                  <Route path="*" element={<Buyer />} />
                </Route>
                <Route path="buyer/settings" element={<BuyerSettings />} >
                  <Route index element={<BuyerGeneralSettings />} />
                  <Route path="general" element={<BuyerGeneralSettings />} />
                  <Route path="profile" element={<BuyerProfileSettings />} />
                  <Route path="password" element={<BuyerPasswordSettings />} />
                </Route>
                <Route path="buyer/create" element={<BuyerCreate />} >
                  <Route path="*" element={<BuyerCreate />} />
                </Route>
                <Route path="buyer/:id" element={<BuyerEdit />} />
                <Route path="terms-of-use" element={<PrivacyPolicy />} />
                <Route path="*" element={<NoPage />} />
              </Route>
              <Route path="/admin" element={
                <ProtectedAdminRoute>
                  <Layout />
                </ProtectedAdminRoute>
              }>
                <Route index element={<Admin />} />
              </Route>
            </Route>
            <Route path="/account" element={<Account />}>
              <Route index element={<AccountLogin />} />
              <Route path="login" element={<AccountLogin />} />
              <Route path="register" element={<AccountRegister />} />
              <Route path="confirm" element={<AccountConfirm />} />
              <Route path="request-reset" element={<AccountRequestReset />} />
              <Route path="reset" element={<AccountReset />} />
              <Route path="reset-step2" element={<AccountResetStep2 />} />
              <Route path="terms-of-use" element={<PrivacyPolicy />} />
            </Route>
          </Routes>
        </AuthProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;

const buyerTheme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    button: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '25.6px',
      letterSpacing: '0.64px',
    },
  },
  palette: {
    primary: {
      main: '#008986',
    },
    error: {
      main: '#EB5757',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          paddingTop: '11px',
          paddingBottom: '11px',
          lineHeight: '1.25',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: 10,
          backgroundColor: '#fff',
          border: '1px solid',
          borderColor: '#CCCCCC',
          '& .MuiSelect-select': {
            paddingLeft: '20px',
          },
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
            borderColor: '#008986',
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: '#fff',
          },
          '& .select-icon': {
            width: 10,
            height: 7,
            display: 'flex',
            right: '15px',
            position: 'absolute',
            cursor: 'pointer',
            pointerEvents: 'none',
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          padding: '0 5px 20px',
          overflow: 'auto',
          marginBottom: '10px'
        },
        indicator: {
          display: 'none'
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '8px',
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor: 'transparent',
                opacity: 1,
              },
              '& .MuiSwitch-thumb': {
                color: '#FED701',
                marginLeft: '0px',
              }
            },
          },
          '.MuiSwitch-track': {
            backgroundColor: 'transparent',
            border: '1px solid #000000',
            borderRadius: '10px',
            opacity: 1,
          },
          '& .MuiSwitch-thumb': {
            color: '#008986',
            boxShadow: 'none',
            width: 18,
            height: 18,
            margin: 2,
          },
          '& .MuiSwitch-thumb:hover': {
            boxShadow: 'none',
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            color: "#000",
            fontSize: '21px',
            fontWeight: 400,
            lineHeight: '25px',
            letterSpacing: '0.04em',
          },
          '&.Mui-selected': {
            backgroundColor: '#fff',
            boxShadow: '0px 10px 10px 0px #0089861A',
            borderRadius: '10px',
            color: "#000",
            fontWeight: 600,
          },
        },
      }
    }
  }
});

const vendorTheme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    button: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '25.6px',
      letterSpacing: '4%',
    },
  },
  palette: {
    primary: {
      main: '#FED701',
    },
    error: {
      main: '#EB5757',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          paddingTop: '11px',
          paddingBottom: '11px',
          lineHeight: '1.25',
          '&.MuiButton-contained:not(.Mui-disabled)': {
            color: "#000",
          },
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: 10,
          backgroundColor: '#fff',
          border: '1px solid',
          borderColor: '#CCCCCC',
          '& .MuiSelect-select': {
            paddingLeft: '20px',
          },
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
            borderColor: '#FED701',
          },
          '& .MuiSelect-select:focus': {
            backgroundColor: '#fff',
          },
          '& .select-icon': {
            width: 10,
            height: 7,
            display: 'flex',
            right: '15px',
            position: 'absolute',
            cursor: 'pointer',
            pointerEvents: 'none',
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          padding: '0 5px 20px',
          overflow: 'auto',
          marginBottom: '10px'
        },
        indicator: {
          display: 'none'
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '8px',
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor: 'transparent',
                opacity: 1,
              },
              '& .MuiSwitch-thumb': {
                color: '#FED701',
                marginLeft: '0px',
              }
            },
          },
          '.MuiSwitch-track': {
            backgroundColor: 'transparent',
            border: '1px solid #000000',
            borderRadius: '10px',
            opacity: 1,
          },
          '& .MuiSwitch-thumb': {
            color: '#008986',
            boxShadow: 'none',
            width: 18,
            height: 18,
            margin: 2,
          },
          '& .MuiSwitch-thumb:hover': {
            boxShadow: 'none',
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.MuiTab-root': {
            color: "#000",
            fontSize: '21px',
            fontWeight: 400,
            lineHeight: '25px',
            letterSpacing: '0.04em',
          },
          '&.Mui-selected': {
            backgroundColor: '#fff',
            boxShadow: '0px 10px 10px 0px #0089861A',
            borderRadius: '10px',
            color: "#000",
            fontWeight: 600,
          },
        },
      }
    }
  }
});