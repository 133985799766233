import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { alpha, styled } from '@mui/material/styles';
import { relative } from 'path';

export const InputText = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme }) => ({
  '&': {
    borderRadius: 10,
    paddingTop: '4px', 
    paddingBottom: '4px', 
    position: 'relative',
  },
  '&. .Mui-disabled': {
    backgroundColor: 'grey'
  },
  '& .MuiFormLabel-root': {
    top: '6px',
    left: '9px',
  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 10,
    backgroundColor: '#fff',
    border: '1px solid',
    borderColor: '#CCCCCC',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-error': {
      borderColor: '#EB5757',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '20px'
    },
    '& .Mui-disabled': {
      backgroundColor: 'grey'
    }
  },
  '.MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.MuiFormHelperText-contained' : {
    // position: 'absolute',
    bottom: '-12px',
  }
}));