import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InputText } from './elements/inputText';
import { Box, Button, Container, Dialog, DialogContentText, DialogTitle, DialogContent, Typography } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { emailPattern } from '../utils/index';
import { useResponseError } from '../hooks/useResponseError';
import { requestPasswordResetAuth } from '../service/authService';
import { useTheme } from '@mui/material/styles';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

type RegisterFormFields = {
	email: string;
};

const AccountRequestReset = () => {
	const navigate = useNavigate();
	const { responseError, setResponseError } = useResponseError();
	const theme = useTheme();
	const [succesMsg, setSuccessMsg] = useState('');
	const [isModalResentOpened, setIsModalResentOpened] = useState<boolean>(false);

	const {
		control,
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors, dirtyFields },
	} = useForm<RegisterFormFields>({
		defaultValues: {
			email: '',
		},
		mode: "onChange",
	});

	const onSubmit = async (data: any) => {
		try {
			const res = await requestPasswordResetAuth({
				data
			});
			setIsModalResentOpened(true);
			setSuccessMsg('Please check your email. If you do not see the message in your inbox, please check spam.')
			ReactGA.event({
				category: 'Account',
				action: 'RequestResetPassword',
				label: 'Request reset password',
			});
		} catch (error: any) {
			setResponseError(error.message)
		}

	}

	return <>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" style={{
				fontWeight: '700',
				fontSize: '21px',
				color: '#000',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Reset password
			</Typography>

			<Box
				sx={{
					'& > :not(style)': { my: 0.5, width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="body1" style={{
					fontSize: '21px',
					color: '#000',
					textAlign: 'center',
					marginBottom: '20px',
				}}>
					Enter your email address<br />
					to receive a link to reset your password.
				</Typography>
				<Box
					component="form"
					autoComplete="off"
					onSubmit={handleSubmit(onSubmit)}
					sx={{
						'& > :not(style)': { my: 0.5, width: '100%' },
						'display': 'flex',
						'flexDirection': 'column',
						'allignItems': 'center',
						'width': '100%',
					}}
				>
					<InputText
						label="Email address"
						variant="filled"
						inputProps={{ ...register("email", { required: true, pattern: emailPattern.value }) }}
						error={!!errors['email']}
						helperText={errors['email'] ? 'Please enter a valid value' : ''}
					/>
					<Box sx={{ textAlign: 'left', color: theme.palette.error.main }}>{responseError}</Box>
					<Box sx={{ textAlign: 'center' }}>
						<Button type="submit" sx={{ mt: '2px', py: '8px', borderRadius: '10px', width: '100%' }} variant="contained">Send email</Button>
					</Box>
				</Box>
			</Box>
			<Dialog
				sx={[{
					'.MuiDialog-paper': {
						px: '55px',
						py: '10px',
						pt: '20px',
						maxWidth: '440px',
						boxSizing: 'border-box',
						width: '100%'
					}
				}, (theme) => ({
					[theme.breakpoints.down('md')]: {
						'.MuiDialog-paper': {
							px: '15px',
						}
					}
				})]
				}
				open={isModalResentOpened}
				onClose={() => setIsModalResentOpened(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<CloseModal close={() => setIsModalResentOpened(false)} />
				<DialogTitle sx={{
					fontWeight: '700',
					textAlign: 'center'
				}}>
					{responseError ? "Something went wrong" : `Request has been submitted successfully`}
				</DialogTitle>
				{!responseError && succesMsg && succesMsg.length > 0 && <DialogContent>
					<DialogContentText sx={{
						fontWeight: '400',
						fontSize: '16px',
						color: '#008986',
						mb: '5px',
						textAlign: 'center'
					}}>
						{succesMsg}
					</DialogContentText>
				</DialogContent>}
			</Dialog>
		</Box>
	</>;
};

export default AccountRequestReset;
