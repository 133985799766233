import { Container, Box, Typography } from "@mui/material";

type TProps = {
  emptyConversation: any;
  children: React.ReactNode;
}

export const ConversationWrapper = ({
  emptyConversation,
  children
}:TProps) => {

  return (
    <Container maxWidth="xl" sx={[{ position: 'relative', padding: 0 }]}>        
      <Box sx={[{
          position: 'relative',
          display: 'grid',
          pt: '60px',
          pb: '11px',
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            pt: '55px',
          },
          [theme.breakpoints.down('sm')]: {
            pt: '40px',
          }
        }),
      ]}>
        {
          emptyConversation ?
          <Box sx={[
            {
              position: 'absolute',
              right: 'calc(50% - 300px)',
              top: '10px',
              textAlign: 'center',
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                top: '10px',
                right: 'calc(50% - 250px)'
              },
              [theme.breakpoints.down(753)]: {
                background: '#fff',
                top: '120px',
                left: '50%',
                transform: "translateX(-50%)",
                width: '100%',
              },
              [theme.breakpoints.down('sm')]: {
                minHeight: '50px',
                top: '100px',
              },
            }),
          ]}>
            <Typography sx={[{
                fontSize: '16px',
                fontWeight: 700,
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: '14px'
                },
              })
            ]}>
              {emptyConversation?.title?.length > 26 ? `${emptyConversation?.title?.slice(0, 26)}...` : emptyConversation?.title}
            </Typography>
            <Typography sx={[{
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '160%'
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: '11px'
                },
              })
            ]}>
              {emptyConversation?.buyerName}
            </Typography>
          </Box>
          : null
        }
        <Box>
          {children}
        </Box>
      </Box>
    </Container>
  )
} 