import { Grid, Box, Typography } from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import BuyerPost from './buyerPost';
import { getPosts } from './../service/postService';
import { STORAGE_AUTH_TOKEN } from '../constants/storage';
import { Loader } from './elements/Loader';

const BuyerArchived = () => {
  const token = localStorage.getItem(STORAGE_AUTH_TOKEN);
  const [fulfilledPosts, setFulfilledPosts] = useState<any>([]);
  const [unfulfilledPosts, setUnfulfilledPosts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const lastPostRef = useRef<HTMLDivElement | null>(null);
  const [hasMoreUnful, setHasMoreUnful] = useState(true);
  const [pageNumberUnful, setPageNumberUnful] = useState(1);

  const [hasMoreFul, setHasMoreFul] = useState(true);
  const [pageNumberFul, setPageNumberFul] = useState(1);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 75 &&
      !isLoading &&
      hasMoreUnful
    ) {
      setPageNumberUnful(pageNumberUnful + 1);
    }
    if (
      window.innerHeight + window.scrollY >= (document.body.scrollHeight / 2) - 75 &&
      !isLoading &&
      hasMoreFul
    ) {
      setPageNumberFul(pageNumberFul + 1);
    }
  };

  const getPostsArrFul = useCallback(async () => {
    if (!hasMoreFul) return;
    setIsLoading(true);

    try {
      const resFul = await getPosts({
        requestStatus: 'ARCHIVED',
        fulfillmentStatus: 'FULFILLED',
        page: pageNumberFul, take: 8
      });

      if (fulfilledPosts.length) {
        setFulfilledPosts((prevPosts: any) => [...prevPosts, ...resFul?.data]);
      } else {
        setFulfilledPosts([...resFul?.data]);
      }
      setHasMoreFul(resFul?.data?.length > 0);
    } catch (error: any) {
      console.error('error', error)
    } finally {
      setIsLoading(false);
    }
  }, [hasMoreFul, pageNumberFul]);

  const getPostsArrUnful = useCallback(async () => {
    if (!hasMoreUnful) return;
    setIsLoading(true);

    try {
      const resUnful = await getPosts({
        requestStatus: 'ARCHIVED',
        fulfillmentStatus: 'UNFULFILLED',
        page: pageNumberUnful, take: 8
      });

      if (unfulfilledPosts.length) {
        setUnfulfilledPosts((prevPosts: any) => [...prevPosts, ...resUnful.data]);
      } else {
        setUnfulfilledPosts([...resUnful.data]);
      }
      setHasMoreUnful(resUnful?.data?.length > 0);
    } catch (error: any) {
      console.error('error', error)
    } finally {
      setIsLoading(false);
    }
  }, [hasMoreUnful, pageNumberUnful]);

  useEffect(() => {
    if (!token) return;
    getPostsArrFul();

    return () => { };
  }, [token, getPostsArrFul, pageNumberFul]);

  useEffect(() => {
    if (!token) return;
    getPostsArrUnful();

    return () => { };
  }, [token, getPostsArrUnful, pageNumberUnful]);

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const renderPostLists = (posts: any) => {
    return posts.map((el: any) => {
      return (
        <Grid key={el?.id} item xs={12} md={6} xl={6}>
          <BuyerPost data={el} />
        </Grid>
      )
    })
  }

  return (
    <Box sx={{
      'mt': '30px',
      'mb': '35px',
    }}>
      {isLoading ? <Loader /> : null}
      <Typography variant="h6" style={{
        color: '#000',
        fontWeight: 400,
        fontSize: '21px',
        marginTop: '20px',
        marginBottom: '20px'
      }}>
        Fulfilled
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {fulfilledPosts?.length > 0 ?
          renderPostLists(fulfilledPosts)
          : <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: '35px',
            width: '100%',
          }}>
            {isLoading ? <Loader /> :
              <Typography variant="h5">
                There are no posts.
              </Typography>}
          </Box>}
      </Grid>
      <div ref={lastPostRef}></div>
      <Typography variant="h6" style={{
        color: '#000',
        fontWeight: 400,
        fontSize: '21px',
        marginTop: '20px',
        marginBottom: '20px'
      }}>
        Unfulfilled
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {unfulfilledPosts?.length > 0 ?
          renderPostLists(unfulfilledPosts)
          : <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: '35px',
            width: '100%',
          }}>
            {isLoading ? <Loader /> :
              <Typography variant="h5">
                There are no posts
              </Typography>}
          </Box>}
      </Grid>
    </Box>
  );
};

export default BuyerArchived;

