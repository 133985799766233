import { useEffect, useState, useRef } from 'react';
import { Inbox, useSession } from '@talkjs/react';
import { ConversationWrapper } from '../components/conversation/ConversationWrapper';
import { TUserProfile } from '../types/types';
import { STORAGE_CONVERSATION, STORAGE_USER } from '../constants/storage';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Loader } from '../components/elements/Loader';
import { subscriptionAccess } from '../utils/subscriptionAccess';
import { getMatchingById } from '../service/matchingService';
import { CloseModal } from '../components/modal/CloseModal';
import Talk from "talkjs";
import { getGeneralPostById, getPostById } from '../service/postService';

function Conversation({ syncConversation }: any) {
  const [user, setUser] = useState<TUserProfile | null>(null);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean | null>(null);
  const [emptyConversation, setEmptyConversation] = useState<any>(null);
  const [conversationData, setConversationData] = useState<any>(null);
  const session = useSession();

  const [open, setOpen] = useState(false);
  const [statusConversation, setStatusConversation] = useState('');
  const [pageInitLoading, setPageInitLoading] = useState<boolean>(true);
  const inbox = useRef<Talk.Inbox>();

  useEffect(() => {
    setTimeout(() => {
      setPageInitLoading(false);
    }, 750);
  }, []);
  
  const handleClose = () => {
    setOpen(false);
  };

  const selectConversation = async (conversation: any) => {
    if (inbox.current?.isAlive && conversation) {
      inbox.current.select(conversation);
    }
  }

  const getSinglePost = async (id: any) => {
    try {
      return await getMatchingById(id);
    } catch {
      return await getGeneralPostById({ id }).then(res => ({
        requestedVehicleItem: res,
      }));
    }
  }

  const onConversationChange = async (conversationData: any) => {
    if (!session) return;

    try {
      setStatusConversation('');
      setOpen(false);
      const data = sessionStorage.getItem(STORAGE_CONVERSATION);
      const { conversation } = conversationData;
      const postId = conversation?.custom?.postId;

      if (postId) {
        const mode = user?.mode;
        const res = mode === 'VENDOR' ? 
          await getSinglePost(postId) :
            await getPostById({
              id: postId
            });
        const isArchived = mode === 'VENDOR' ? res?.requestedVehicleItem?.requestStatus === "ARCHIVED" : res?.requestStatus === "ARCHIVED";
        const isReadOnly = conversation?.custom?.readOnly;
        const conversationBuilder = session?.getOrCreateConversation(conversation?.id);
        const otherData = conversationData?.other;

        const me = new Talk.User({
          id: conversationData.me.id,
          name: conversationData.me.name,
          email: conversationData.me.email,
          photoUrl: conversationData.me.photoUrl,
          role: conversationData.me.role,
        });

        if (isArchived || isReadOnly) {
          setOpen(true);
          setStatusConversation("This conversation is no longer active."); //"This post is no longer active."
          conversationBuilder?.setParticipant(me, {
            access: "Read",
            notify: false,
          });
          if (otherData) {
            const other = new Talk.User({
              id: otherData.id,
              name: otherData.name,
              email: otherData.email,
              photoUrl: otherData.photoUrl,
              role: otherData.role,
            });
            conversationBuilder?.setParticipant(other, {
              access: "Read",
              notify: false,
            });
          }
          selectConversation(conversationBuilder);
        } else {
          setStatusConversation('');
          conversationBuilder?.setParticipant(me, {
            access: "ReadWrite",
            notify: true,
          });
          if (otherData) {
            const other = new Talk.User({
              id: otherData.id,
              name: otherData.name,
              email: otherData.email,
              photoUrl: otherData.photoUrl,
              role: otherData.role,
            });
            conversationBuilder?.setParticipant(other, {
              access: "ReadWrite",
              notify: true,
            });
          }
          selectConversation(conversationBuilder);
        }
      }

      if (data) {
        const { conversationId, conversationTitle, secondUser, currentPostId } = JSON.parse(data);

        if (conversation?.id !== conversationId) {
          setEmptyConversation(null);
        }

        if (conversation?.id === conversationId) {
          setEmptyConversation({
            title: conversationTitle,
            buyerName: secondUser?.name
          });

          session?.onMessage(() => {
            setEmptyConversation(null);
            sessionStorage.removeItem(STORAGE_CONVERSATION);
          });
        }
      }
    } catch (error) {
      console.error('error:', error);
    }
  };

  useEffect(() => {
    if (localStorage && localStorage.getItem(STORAGE_USER)) {
      setUser(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
    }
  }, []);

  useEffect(() => {
    const access = async () => {
      const res = await subscriptionAccess(user?.mode);
      setIsSubscriptionActive(res);
    }
    access();
  }, [user?.mode]);

  useEffect(() => {
    if (conversationData) {
      onConversationChange(conversationData);
    }
  }, [conversationData?.conversation?.id])

  const readOnlyConversation = (conversationData: any) => {
    const { conversation } = conversationData;

    const conversationBuilder = session?.getOrCreateConversation(conversation?.id);

    const myData = new Talk.User(JSON.parse(conversation?.custom?.user1));
    const otherData = new Talk.User(JSON.parse(conversation?.custom?.user2));
    conversationBuilder?.setAttributes({ custom: { readOnly: 'true' } });

    conversationBuilder?.setParticipant(myData, {
      access: "Read",
      notify: false,
    });

    conversationBuilder?.setParticipant(otherData, {
      access: "Read",
      notify: false,
    });

    selectConversation(conversationBuilder);
  }

  if (!isSubscriptionActive && typeof isSubscriptionActive === 'boolean') {
    return (
      <Box sx={[{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '10rem',
        textAlign: 'center'
      }]}>
        <Typography sx={{ fontSize: '16px' }} variant='h6'>Activate a subscription to start connecting with buyers</Typography>
        <Link style={{ color: 'black', fontSize: '16px' }} to="/vendor/settings">Manage subscription</Link>
      </Box>
    );
  }

  if (pageInitLoading) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Dialog
        sx={[{
          '.MuiDialog-paper': {
            px: '55px',
            paddingBottom: '35px',
            pt: '35px',
          }
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            '.MuiDialog-paper': {
              px: '15px',
            }
          }
        }),
        ]}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseModal close={handleClose} />
        <DialogTitle sx={{
          fontWeight: '700',
          maxWidth: '300px',
          textAlign: 'center'
        }}>
          {statusConversation}
        </DialogTitle>
      </Dialog>
      <ConversationWrapper emptyConversation={emptyConversation}>
        <Inbox
          theme={user?.mode === "VENDOR" ? "default_vendor" : "default_buyer"}
          syncConversation={syncConversation}
          style={{ width: '100%', height: 'calc(100vh - 150px)', maxHeight: '580px' }}
          onConversationSelected={(e) => setConversationData({...e})}
          onSendMessage={(e) => onConversationChange(e)}
          onLeaveConversation={readOnlyConversation}
          inboxRef={inbox}
        ></Inbox>
      </ConversationWrapper>
    </>
  );
}

export default Conversation;