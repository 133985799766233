export const emailPattern = {
  value: /^\S+@\S+\.\S+$/,
  message: "Entered value does not match email format",
};

export const passwordPattern = {
  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
  message:
    "Password must contain at least 8 characters, one uppercase, one lowercase and one number",
};

export const mobilePhonePattern = {
  value:
    /^\+?(\(\d{1,3}\)|\d{1,3})?\s?(\d{1,3})?[ -]?(\d{2,4}[ -]?\d{2,4}[ -]?\d{2,4})$/,
  message: "Entered value does not match mobile phone format",
};

export const websiteUrlPattern = {
  value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
  message: "Entered value does not match website url format",
};


interface MyObject {
  [key: string]: string;
}

export function filterEmptyValues(obj: MyObject): MyObject {
  const result: MyObject = {};

  for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== '') {
          result[key] = obj[key];
      }
  }

  return result;
}