import {
  Grid,
  Box,
  Typography,
  SelectChangeEvent,
  FormControl,
  InputAdornment,
  TextField,
  IconButton,
  Button,
  SvgIcon,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useResponseError } from './../hooks/useResponseError';
import { STORAGE_AUTH_TOKEN } from '../constants/storage';
import { useTheme } from '@mui/material/styles';
import AdminUserTable from './adminUserTable';
import { getUsers, removeUser, suspendUserApi } from '../service/userService';
import SelectControl from './elements/SelectControl';
import debounce from "lodash/debounce";
import { useDebounce } from './../hooks/useDebounce';
import { OrderBy, Order } from '../types/types';
import { Loader } from './elements/Loader';
import { CloseModal } from './modal/CloseModal';

const AdminActiveUsers = () => {
  const token = localStorage.getItem(STORAGE_AUTH_TOKEN);
  const [users, setUsers] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    id: ''
  });
  const [openRemove, setOpenRemove] = useState(false);
  const { responseError, setResponseError } = useResponseError();
  const theme = useTheme();
  const [sort, setSort] = useState('ALL');
  const [sortSub, setSortSub] = useState('ALL');
  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof OrderBy>('roles');
  const [showClearIcon, setShowClearIcon] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 1000, '');
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const lastUserRef = useRef<HTMLDivElement | null>(null);
  const [visible, setVisible] = useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof OrderBy,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderValue = isAsc ? 'desc' : 'asc';
    setOrder(orderValue);
    setOrderBy(property);
    setPageNumber(1);
    sortUsers(searchValue, sort, sortSub, orderValue, property, 1);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowClearIcon(event.target.value === "" ? false : true);
    setSearchValue(event.target.value);
  };

  const sortUsers = useCallback(async (
    searchField: string, sortField: string, sortSubField: string, orderField: string, orderByField: string, pgNumber: number
  ) => {
    if (!hasMore) return;

    setIsLoading(true);
    let data: {
      take: number,
      page?: number,
      searchText?: string,
      role?: string,
      subscriptionStatus?: string,
      order: string,
      orderBy: string
    } = {
      page: pgNumber,
      take: 10,
      order: orderField,
      orderBy: orderByField
    }

    if (searchField !== '') {
      data.searchText = searchField;
    }

    if (sortField !== "ALL") {
      data.role = sortField;
    }

    if (sortSubField !== "ALL") {
      data.subscriptionStatus = sortSubField;
    }

    try {
      const res = await getUsers(data);
      const formatData = res.data;
      if (pgNumber > 1) {
        setUsers((prevUsers: any) => {
          return [...prevUsers, ...formatData]
        });
      } else {
        setUsers([...formatData]);
      }
      setHasMore(formatData.length > 0);
    } catch (error: any) {
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [setResponseError]);

  const handleClickOpenRemove = () => {
    setOpenRemove(true);
  };

  const handleCloseRemove = () => {
    setOpenRemove(false);
  };

  const handleClick = (): void => {
    setSearchValue('');
    setShowClearIcon(false);
    setPageNumber(1);
    sortUsers('', sort, sortSub, order, orderBy, 1);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSort(event.target.value);
    setPageNumber(1);
    sortUsers(searchValue, event.target.value, sortSub, order, orderBy, 1);
  };

  const handleChangeFilterSub = (event: SelectChangeEvent) => {
    setSortSub(event.target.value);
    setPageNumber(1);
    sortUsers(searchValue, sort, event.target.value, order, orderBy, 1);
  };

  const handleClickUser = (user: any) => {
    setSelectedUser(user)
    handleClickOpenRemove();
  }

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 75 &&
      !isLoading &&
      hasMore
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setVisible(true) 
    }  
    else if (scrolled <= 300){ 
      setVisible(false) 
    } 
  }; 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
    }); 
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);

    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
}, []);

  useEffect(() => {
    if (debouncedSearchValue) {
      sortUsers(debouncedSearchValue, sort, sortSub, order, orderBy, 1);
    }

    if (searchValue || debouncedSearchValue) {
      setHasMore(true);
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (pageNumber) {
      sortUsers(debouncedSearchValue, sort, sortSub, order, orderBy, pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const onSubmitSuspend = async () => {
    const suspend = selectedUser?.disabled?.props?.children?.toLowerCase() === 'active';
    const newUsers = [...users];
    const index = users.findIndex((u: any) => u.id === selectedUser.id);
    newUsers[index].disabled = suspend;

    try {
      await suspendUserApi({ id: selectedUser?.id, suspend });
      setOpenRemove(false);
      setUsers(newUsers);
    } catch (error: any) {
      setResponseError(error.message);
    }
  }

  const onSubmitRemove = async () => {
    try {
      const res = await removeUser({
        id: selectedUser.id,
      })
      setPageNumber(1);
      sortUsers(searchValue, sort, sortSub, order, orderBy, 1);
      setOpenRemove(false);
    } catch (error: any) {
      setResponseError(error.message);
    }
  }
  const renderNoPostinfo = () => {

    if (isLoading) {
      return (<Loader />)
    }

    if (!isLoading && responseError) {
      return (
        <Box sx={{
          justifyContent: 'center',
          textAlign: 'center',
          color: theme.palette.error.main
        }}>
          {responseError}
        </Box>
      )
    }

    return (
      <Typography variant="h5">
        No users found
      </Typography>
    )
  }

  return (<>
    <Box sx={[{
      display: 'flex',
      alignItems: 'center',
      mb: '10px',
    },
    (theme) => ({
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    }),
    ]}>
      <Box sx={[{
        display: 'flex',
        alignItems: 'center',
        mr: '20px'
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          mb: '20px'
        }
      }),
      ]}>
        <Typography sx={{ mr: '20px' }} variant='body1'>Show</Typography>
        <SelectControl
          sx={{
            maxWidth: '130px',
            width: '100%',
            '.MuiSelect-select': {
              px: '10px',
              py: '7.5px'
            }
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Filter by User' }}
          items={
            [
              { value: 'ALL', label: 'All' },
              { value: 'ADMIN', label: 'Admin' },
              { value: 'VENDOR', label: 'Vendor' },
              { value: 'BUYER', label: 'Buyer' },
            ]
          }
          onChange={handleChange}
          value={sort}
        />
      </Box>
      <Box sx={[{
        display: 'flex',
        alignItems: 'center'
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          mb: '20px'
        }
      }),
      ]}>
        <Typography sx={{ mr: '20px' }} variant='body1'>Subscription</Typography>
        <SelectControl
          sx={{
            maxWidth: '230px',
            width: '100%',
            '.MuiSelect-select': {
              px: '10px',
              py: '7.5px'
            }
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Filter by Subscription' }}
          items={
            [
              { value: 'ALL', label: 'All' },
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' },
            ]
          }
          onChange={handleChangeFilterSub}
          value={sortSub}
        />
      </Box>
      <FormControl sx={[
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            mb: '40px'
          }
        })
      ]}>
        <TextField
          sx={[{
            backgroundColor: '#fff',
            borderRadius: '10px',
            maxWidth: '180px',
            ml: '20px',
            '.MuiInputBase-root': {
              borderRadius: '10px'
            }
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              m: 0,
              width: '100%',
              maxWidth: '100%'
            }
          }),
          ]}
          label="Search"
          size="small"
          variant="outlined"
          onChange={handleChangeSearch}
          value={searchValue}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  cursor: 'pointer'
                }}
                position="end"
                onClick={handleClick}
              >
                {showClearIcon ?
                  <SvgIcon>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11 11L19 19" stroke="#666666" />
                      <path d="M11 19L19 11" stroke="#666666" />
                    </svg>
                  </SvgIcon> :
                  <SvgIcon>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="13.4853" cy="13.4854" r="5.5" transform="rotate(-45 13.4853 13.4854)" stroke="#666666" />
                      <path d="M17.7285 17.728L21.9712 21.9707" stroke="#666666" />
                    </svg>
                  </SvgIcon>
                }
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </Box>
    <Box sx={{
      'mb': '35px',
    }}>
      <Box>
        {users?.length > 0 ?
          <AdminUserTable
            order={order}
            orderBy={orderBy}
            handleClickUser={handleClickUser}
            handleRequestSort={handleRequestSort}
            data={users}
            page={pageNumber}
          />
          : <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mt: '35px',
            width: '100%',
          }}>
            {renderNoPostinfo()}
          </Box>
        }
      </Box>
    </Box>
    {visible && <Box sx={{
      position: 'fixed',
      bottom: '40px',
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Button onClick={scrollToTop} variant="text">Back to top</Button>
    </Box>}
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          position: 'relative',
          px: '55px',
          paddingBottom: '35px',
          pt: '20px',
          maxWidth: '440px',
          boxSizing: 'border-box',
          width: '100%'
        }
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      }),
      ]}
      open={openRemove}
      onClose={handleCloseRemove}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseModal close={handleCloseRemove} />
      <DialogTitle sx={{
        fontWeight: '700',
        textAlign: 'center'
      }}>
        {selectedUser?.roles?.includes("ADMIN") ? `Edit Admin` : selectedUser?.disabled?.props?.children?.toLowerCase() === 'active' ? `Suspend user` : `Un-suspend user`}
      </DialogTitle>
      <DialogContent sx={{
        textAlign: 'center',
        display: `${selectedUser?.disabled?.props?.children?.toLowerCase() === 'active' ? 'block' : 'none'}`
      }}>
        <DialogContentText sx={{
          fontWeight: '400',
          color: 'black',
          mb: '5px'
        }}>
          {selectedUser.firstName} {selectedUser.lastName}
        </DialogContentText>
        <DialogContentText sx={{
          fontWeight: '400',
          color: 'black',
          mb: '5px'
        }}>
          {selectedUser.email}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          display: `${selectedUser?.roles?.includes("ADMIN") ? 'flex' : 'none'}`
        }}
      >
        <Button
          sx={{
            padding: '11px 20px',
            borderRadius: '10px',
            width: '100%',
          }}
          variant="contained"
          color='error'
          onClick={onSubmitRemove}
          autoFocus
        >
          Delete user
        </Button>
      </DialogActions>
      <DialogContent
        sx={{
          borderTop: `${selectedUser?.roles?.includes("ADMIN") ? '1px solid #CCC' : '0'}`,
          marginTop: `${selectedUser?.roles?.includes("ADMIN") ? '22px' : '0'}`,
          paddingTop: `${selectedUser?.roles?.includes("ADMIN") ? '30px' : '0'}`
        }}
      >
        <DialogContentText sx={{
          fontWeight: '400',
          color: 'black',
          textAlign: 'center'
        }}>
          {
            selectedUser?.disabled?.props?.children?.toLowerCase() !== 'active' ? `You will give the user back the ability to log in.`
            : selectedUser?.roles?.includes("ADMIN") ? 'You can also suspend an admin. Suspending an admin prevents them from logging in.'
            : 'Suspending a user prevents them from logging in.'
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
        }}
      >
        {selectedUser?.disabled?.props?.children?.toLowerCase() === 'active' ?
          <Button
            sx={{
              padding: '11px 20px',
              borderRadius: '10px',
              width: '100%',
              border: '1px solid #EB5757',
              color: '#EB5757 !important',
              backgroundColor: '#fff',
              ":hover": {
                color: '#fff !important',
                backgroundColor: '#EB5757',
              }
            }}
            variant="contained"
            onClick={onSubmitSuspend}
            autoFocus
          >
            Suspend user
          </Button> :
          <Button
            sx={{
              padding: '11px 20px',
              borderRadius: '10px',
              width: '100%',
              border: '1px solid #008986',
              color: '#fff',
              backgroundColor: '#008986',
              ":hover": {
                color: '#008986',
                backgroundColor: '#fff',
              }
            }}
            variant="contained"
            color="inherit"
            onClick={onSubmitSuspend}
            autoFocus
          >
            Un-suspend user
          </Button>
        }
      </DialogActions>
      <Box sx={{
        justifyContent: 'center',
        textAlign: 'center',
        color: theme.palette.error.main
      }}>
        {responseError}
      </Box>
    </Dialog>
  </>);
};

export default AdminActiveUsers;

