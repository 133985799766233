import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { OrderBy, Order } from '../types/types';
import { visuallyHidden } from '@mui/utils';

interface Data {
  roles: string;
  firstName: string;
  lastName: string;
  email: string;
  location: string,
  verified: boolean,
  subscription: boolean,
  disabled: boolean,
}

interface Column {
  id: 'roles' | 'firstName' | 'lastName' | 'email' | 'location' | 'disabled' | 'verified' | 'subscription';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  {
    id: 'roles',
    label: 'Role',
    minWidth: 120,
  },
  {
    id: 'firstName',
    label: 'First name',
    minWidth: 100,
  },
  {
    id: 'lastName',
    label: 'Last name',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Email address',
    minWidth: 100,
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 100,
  },
  {
    id: 'verified',
    label: 'Verification',
    minWidth: 80,
    align: 'right'
  },
  {
    id: 'subscription',
    label: 'Subscription',
    minWidth: 80,
    align: 'right'
  },
  {
    id: 'disabled',
    label: 'Status',
    minWidth: 120,
    align: 'right'
  },
];

interface AdminUserTableProps {
  data: any;
  handleRequestSort: any,
  handleClickUser: any,
  order: Order,
  orderBy: keyof OrderBy,
  page: number,
}

const AdminUserTable: React.FC<AdminUserTableProps> = (
  { data, handleRequestSort, handleClickUser, order, orderBy, page }
) => {
  const theme = useTheme();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data) {
      setRows(formatUserData(data));
    }
  }, [data]);

  const formatUserData = (arr: any) => {
    return arr.map((obj: any) => {
      const { zip, city, state, ...rest } = obj;
      const location = [state, city, zip].filter(Boolean).join(', ');
      const roles = obj.roles.join(', ');
      const verified = obj.verified ?
        <Typography
          sx={{ color: '#008986', fontWeight: '700' }}
          variant='body1'
        >Verified</Typography> :
        <Typography
          sx={{ color: '#b19600', fontWeight: '700' }}
          variant='body1'
        >Pending</Typography>;
      const subscription = obj.hasActiveSubscription ?
        <Typography
          sx={{ color: '#008986', fontWeight: '700' }}
          variant='body1'
        >Active</Typography> :
        <Typography
          sx={{ color: '#b19600', fontWeight: '700' }}
          variant='body1'
        >Inactive</Typography>;
      const disabled = obj.disabled ?
        <Typography
          sx={{ color: theme.palette.error.main, fontWeight: '700' }}
          variant='body1'
        >Suspended</Typography> :
        <Typography
          sx={{ color: '#008986', fontWeight: '700' }}
          variant='body1'
        >Active</Typography>;

      return {
        ...rest,
        roles,
        location,
        verified,
        subscription,
        disabled,
      };
    });
  }

  const renderRows = (arrRows: any) => {
    return arrRows.map((row: any, rowId: any) => {
      return (
        <TableRow
          sx={{
            cursor: 'pointer',
            '&.MuiTableRow-hover:hover': {
              position: 'relative',
              backgroundColor: 'transparent',
            },
            '&.MuiTableRow-hover:hover td:first-of-type::before': {
              content: '""',
              position: 'absolute',
              top: '7%',
              bottom: '7%',
              left: '-25px',
              right: '-25px',
              width: 'calc(100% + 50px)',
              backgroundColor: '#fff',
              height: '86%',
              borderRadius: '10px',
              boxShadow: '0px 10px 10px 0px rgba(0, 137, 134, 0.1)',
              zIndex: '-1',
            },
          }}
          hover role="checkbox" tabIndex={-1} key={row.id + rowId}
          onClick={() => {
            handleClickUser(row);
          }}
        >
          {columns.map((column, id) => {
            const value = row[column.id];
            return (
              <TableCell
                sx={{
                  color: '#000',
                  fontSize: '16px',
                  fontWeight: '400',
                  px: '0px',
                  pr: '10px',
                }}
                key={row.id + id + rowId}
                align={column.align}
              >
                {column.format && typeof value === 'number'
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
      );
    })
  }

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      if (property !== 'subscription') {
        handleRequestSort(event, property);
      }
    };

  return (
    <div>
      <Paper sx={{
        width: '100%',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& .MuiTableRow-hover:hover': {
          position: 'relative',
          backgroundColor: '#fff',
        },
        '.MuiTableContainer-root': {
          overflow: 'visible'
        }
      }}>
        <TableContainer>
          <Table sx={{
            backgroundColor: 'transparent',
          }}>
            <TableHead>
              <TableRow>
                {columns.map((column, id) => (
                  <TableCell
                    sx={[
                      {
                        color: '#666666',
                        fontSize: '12px',
                        fontWeight: '400',
                        py: '10px',
                        px: '0px',
                        pr: '10px',
                        '& svg': {
                          visibility: column.id === 'subscription' ? 'hidden' : 'visible', 
                        }
                      },
                      (theme) => ({
                        [theme.breakpoints.up('md')]: {
                          minWidth: column.minWidth
                        }
                      }),
                    ]}
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {renderRows(rows)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>);
};

export default AdminUserTable;