import { useEffect, useState } from "react";
import Breadcrumb from "../components/elements/Breadcrumbs";
import { TBreadcrumbs } from "../types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, SvgIcon, Tooltip, Typography } from "@mui/material";
import { changeMatchingById, getMatchingById, updateMatchingById } from "../service/matchingService";
import { getConversationId } from "../service/conversationService";
import { STORAGE_CONVERSATION } from "../constants/storage";
import { getChatUserId } from "../utils/conversation";
import ReactGA from "react-ga4";
import { getGeneralPostById } from "../service/postService";
import { Loader } from "../components/elements/Loader";

const basicTextStyle = {
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '160%',
}

const SingleMatching = () => {
  const [links, setLinks] = useState<TBreadcrumbs[]>([{
    title: 'Home', url: '/vendor'
  }]);
  const [currentPost, setCurrentPost] = useState<any>(null);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [isNotInterested, setIsNotInterested] = useState<boolean>(false);
  const [datePost, setDatePost] = useState<any>(null);

  const getSinglePost = async (id: string) => {
    try {
      const res = await getMatchingById(id);
      setCurrentPost(res);
      setIsFavorite(res?.favorite);
      setIsNotInterested(res?.bookmark === 'NOT_INTERESTED');
      const date = new Date(Date.parse(res?.requestedVehicleItem?.createdAt));
      setDatePost(`${date.getMonth() + 1}-${date.getDate()}-${String(date.getFullYear()).slice(2)}`);
      setIsSubscriptionActive(true);
    } catch (error: any) {
      if ('active/trialing subscription required' === error?.message) {
        setIsSubscriptionActive(false);
      } else {
        try {
          const res = await getGeneralPostById({ id });
          setCurrentPost({...res, requestedVehicleItem: res});
          setIsFavorite(res?.favorite);
          setIsNotInterested(res?.bookmark === 'NOT_INTERESTED');
          const date = new Date(Date.parse(res?.createdAt));
          setDatePost(`${date.getMonth() + 1}-${date.getDate()}-${String(date.getFullYear()).slice(2)}`);
          setIsSubscriptionActive(true);
        } catch (error) {
          navigate('/not-found');          
        }
      }
    }
  }

  useEffect(() => {
    if (currentPost && links[links.length - 1].title !== currentPost?.requestedVehicleItem.name) {
      setLinks([...links, { title: currentPost?.requestedVehicleItem?.name, url: '' }]);
    }

    if (!currentPost) {
      const id = location.pathname
        .split('/')[location.pathname.split('/').length - 1];
      getSinglePost(id);
    }
  }, [currentPost, location.pathname]);

  const handleSendMessage = async () => {
    try {
      const res = await getConversationId(currentPost?.requestedVehicleItem?.id);
      console.log(currentPost);

      sessionStorage.setItem(STORAGE_CONVERSATION, JSON.stringify(
        {
          conversationId: res.id,
          current: currentPost?.requestedVehicleItem?.id || currentPost?.id,
          currentPostId: currentPost?.requestedVehicleItem?.id || currentPost?.id,
          conversationTitle: currentPost?.requestedVehicleItem?.name || currentPost?.name,
          secondUser: {
            id: getChatUserId(
              currentPost?.requestedVehicleItem?.user?.id || currentPost?.user?.id,
              "BUYER"
            ),
            email: null,
            name: currentPost?.requestedVehicleItem?.user?.firstName || currentPost?.user?.firstName,
            role: "Buyer",
            photoUrl: null,
          },
        }
      ));
      
      navigate('/conversations', {
        state: {
          conversationId: res.id,
          current: currentPost?.requestedVehicleItem?.id || currentPost?.id,
          currentPostId: currentPost?.requestedVehicleItem?.id || currentPost?.id,
          conversationTitle: currentPost?.requestedVehicleItem?.name || currentPost?.name,
          secondUser: {
            id: getChatUserId(
              currentPost?.requestedVehicleItem?.user?.id || currentPost?.user?.id,
              "BUYER"
            ),
            email: null,
            name: currentPost?.requestedVehicleItem?.user?.firstName || currentPost?.user?.firstName,
            role: "Buyer",
            photoUrl: null,
          },
        }
      });
    } catch (error) {
      console.error('conversation create error:', error);
    }
  }

  const handleFavorite = async () => {
    try {
      await updateMatchingById({ id: currentPost?.requestedVehicleItem?.id, bookmark: !isFavorite ? "FAVORITE" : "GENERAL" });
      ReactGA.event('favoritePost', {
        idPost: currentPost?.inventoryId,
        requestedVehicleItemId: currentPost?.requestedVehicleItem,
      });
      setIsFavorite(!isFavorite);
      navigate(0);
    } catch (error) {
      await changeMatchingById({ id: currentPost?.requestedVehicleItem?.id, bookmark: !isFavorite ? "FAVORITE" : "GENERAL" });
      ReactGA.event('favoritePost', {
        idPost: currentPost?.inventoryId,
        requestedVehicleItemId: currentPost?.requestedVehicleItem,
      });
      setIsFavorite(!isFavorite);
      navigate(0);
    }
  }

  const handleNotInterested = async () => {
    try {
      await updateMatchingById({ id: currentPost?.requestedVehicleItem?.id, bookmark: !isNotInterested ? "NOT_INTERESTED" : "GENERAL" });

      ReactGA.event('NotInterestedPost', {
        idPost: currentPost?.inventoryId,
        requestedVehicleItemId: currentPost?.requestedVehicleItem,
      });
      setIsNotInterested(!isNotInterested);
      navigate(0);
    } catch (error) {
      await changeMatchingById({ id: currentPost?.requestedVehicleItem?.id, bookmark: !isFavorite ? "NOT_INTERESTED" : "GENERAL" });

      ReactGA.event('NotInterestedPost', {
        idPost: currentPost?.inventoryId,
        requestedVehicleItemId: currentPost?.requestedVehicleItem,
      });
      setIsNotInterested(!isNotInterested);
      navigate(0);
    }
  }


  if (!currentPost) {
    return <Loader />;
  }

  return (
    <Box
      sx={[{
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 5% 11px',
        width: '670px',
      },
      (theme: any) => ({
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          maxWidth: '100%',
          padding: '40px 4px 10px'
        }
      }),
      ]}
    >
      <Breadcrumb links={links} />

      {/* PAGE TITLE */}
      <Box
        sx={[{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mt: '30px',
          mb: '30px',
          width: '100%',
          maxWidth: '95%'
        }]}
      >
        <Box>
          <Typography
            sx={[
              {
                fontFamily: 'Inter',
                fontSize: '34px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              },
              (theme) => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: '21px'
                }
              })
            ]}
          >
            {currentPost?.requestedVehicleItem?.name}
          </Typography>
          {currentPost?.requestedVehicleItem?.shippingType === "EVENT_PICKUP" && currentPost?.requestedVehicleItem?.eventName && <Box sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            mt: '10px',
            mb: '10px',
          }}>
            <Typography sx={{ fontSize: '13px', lineHeight: '21px' }}>Event: {currentPost?.requestedVehicleItem?.eventName}</Typography>
          </Box>}
        </Box>

        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <Tooltip title={!isNotInterested ? "Add to not interested" : "Remove from not interested"}>
            <SvgIcon
              sx={{
                fontSize: 40,
                cursor: 'pointer',
                '&:hover path, &:hover circle': {
                  stroke: !isNotInterested ? '#EB5757' : '#CCCCCC'
                }
              }}>
              {!isNotInterested ?
                <svg onClick={(e) => {
                  e.stopPropagation();
                  handleNotInterested();
                }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" />
                  <path d="M33.4326 20C32.3087 22.0612 30.8264 23.898 28.7533 25.2368C26.6045 26.6244 23.7855 27.5 20 27.5C16.2145 27.5 13.3955 26.6244 11.2467 25.2368C9.17358 23.898 7.69128 22.0612 6.56743 20C7.69128 17.9388 9.17358 16.102 11.2467 14.7632C13.3955 13.3756 16.2145 12.5 20 12.5C23.7855 12.5 26.6045 13.3756 28.7533 14.7632C30.8264 16.102 32.3087 17.9388 33.4326 20Z" stroke="#CCCCCC" />
                  <circle cx="20" cy="20" r="4.5" stroke="#CCCCCC" />
                  <path d="M11 11L29 29" stroke="#CCCCCC" />
                </svg>
                :
                <svg onClick={(e) => {
                  e.stopPropagation();
                  handleNotInterested();
                }} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" />
                  <path d="M33.4326 20C32.3087 22.0612 30.8264 23.898 28.7533 25.2368C26.6045 26.6244 23.7855 27.5 20 27.5C16.2145 27.5 13.3955 26.6244 11.2467 25.2368C9.17358 23.898 7.69128 22.0612 6.56743 20C7.69128 17.9388 9.17358 16.102 11.2467 14.7632C13.3955 13.3756 16.2145 12.5 20 12.5C23.7855 12.5 26.6045 13.3756 28.7533 14.7632C30.8264 16.102 32.3087 17.9388 33.4326 20Z" stroke="#EB5757" />
                  <circle cx="20" cy="20" r="4.5" stroke="#EB5757" />
                  <path d="M11 11L29 29" stroke="#EB5757" />
                </svg>
              }
            </SvgIcon>
          </Tooltip>
          <Tooltip title={!isFavorite ? "Add to favorites" : "Remove from favorites"}>
            <SvgIcon
              sx={{
                fontSize: 40,
                cursor: 'pointer',
                '&:hover': {
                  fill: !isFavorite ? '#FED701' : 'none'
                },
                '&:hover path': {
                  stroke: !isFavorite ? '#FED701' : '#666666'
                }
              }}>
              <svg
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavorite();
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill={!isFavorite ? "none" : "#FED701"}
              >
                <path d="M20 8.32961L22.9218 15.5289L23.0388 15.8174L23.3494 15.8396L31.0992 16.3937L25.1551 21.3971L24.9169 21.5976L24.9918 21.8998L26.8597 29.4415L20.2643 25.3346L20 25.17L19.7357 25.3346L13.1403 29.4415L15.0082 21.8998L15.0831 21.5976L14.8449 21.3971L8.9008 16.3937L16.6506 15.8396L16.9612 15.8174L17.0782 15.5289L20 8.32961Z" stroke={!isFavorite ? "#666666" : "#FED701"} />
              </svg>
            </SvgIcon>
          </Tooltip>
        </Box>
      </Box>

      {/* INFO BLOCK */}
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: '10px',
            width: 'calc(100% + 20px)',
            ml: '-20px'
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column !important',
              ml: '-10px'
            }
          })
        ]}
      >
        {/* LOCATION  */}
        <Box
          sx={[
            {
              backgroundColor: '#FFF',
              filter: 'drop-shadow(0px 10px 10px rgba(0, 137, 134, 0.10))',
              padding: '10px 10px 10px 20px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              width: 'calc(100% - 10px - 190px - 20px)',
              alignItems: 'center',
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                width: 'calc(100% - 40px)'
              }
            })
          ]}
        >
          <Box>
            <Typography
              sx={[{
                ...basicTextStyle,
                color: '#666'
              }]}
            >
              {datePost}
            </Typography>
            <Typography
              sx={[{
                ...basicTextStyle,
                color: '#000'
              }]}
            >
              {currentPost?.requestedVehicleItem?.user?.firstName}
            </Typography>
          </Box>
          <Box
            sx={[{
              display: 'flex',
            }]}
          >
            <Box>
              <Typography
                sx={[{
                  ...basicTextStyle,
                  color: '#000',
                  textAlign: 'right'
                }]}
              >
                {currentPost?.requestedVehicleItem?.city}, {currentPost?.requestedVehicleItem?.state}
              </Typography>
              <Typography
                sx={[{
                  ...basicTextStyle,
                  color: '#008986',
                  textAlign: 'right'
                }]}
              >
                +{currentPost?.requestedVehicleItem?.pickupRadius}m
              </Typography>
            </Box>
            <SvgIcon sx={{ fontSize: 40 }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M24.4038 26.9297C27.1469 22.7418 29 19.9126 29 16.6667C29 11.8802 24.9706 8 20 8C15.0294 8 11 11.8802 11 16.6667C11 19.9126 12.8531 22.7418 15.5962 26.9297C16.656 28.5477 17.8486 30.3685 19.1185 32.4945C19.5146 33.1575 20.4854 33.1575 20.8815 32.4945C22.1514 30.3685 23.344 28.5477 24.4038 26.9297ZM20 21C22.7614 21 25 18.9853 25 16.5C25 14.0147 22.7614 12 20 12C17.2386 12 15 14.0147 15 16.5C15 18.9853 17.2386 21 20 21Z" fill="#CCCCCC" />
              </svg>
            </SvgIcon>
          </Box>
        </Box>
        {/* BUTTON  */}
        <Box
          sx={[
            {
              backgroundColor: '#FFF',
              filter: 'drop-shadow(0px 10px 10px rgba(0, 137, 134, 0.10))',
              padding: '10px',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center'
            },
            (theme) => ({
              [theme.breakpoints.down('md')]: {
                backgroundColor: 'transparent',
              }
            })
          ]}
        >
          <Tooltip title={!isSubscriptionActive ? "Activate a subscription to start connecting with buyers" : ""}>
            <Button
              sx={[{
                backgroundColor: '#FED701',
                color: '#000',
                borderRadius: '10px',
                padding: '8px 20px',
                width: '190px',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '160%',
                ":hover": {
                  backgroundColor: '#FED701',
                  opacity: '0.85'
                }
              }]}
              onClick={() => {
                if (isSubscriptionActive) {
                  handleSendMessage();
                }
              }}
            >
              send a message
            </Button>
          </Tooltip>
        </Box>
      </Box>

      <Box
        sx={[{ mt: '14px' }]}
      >
        <div dangerouslySetInnerHTML={{ __html: currentPost?.requestedVehicleItem?.description }} />
      </Box>
    </Box>
  );
};

export default SingleMatching;