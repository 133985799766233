export const handleApiError = (error: any): void => {
  console.error('Handle Api Error', error)
  const errorMessage = error.response?.data?.message;

  if (error.response?.status === 401) {
    
    if (window.location.pathname.includes('/account')) {
      throw new Error('Incorrect credentials or account not verified')
    } else {
      window.location.replace("/account/login");
    }
  }

  if (error.response?.data?.type === "DB-002") {
    throw new Error(errorMessage + '. Please login.')
  }

  if (Array.isArray(errorMessage) && errorMessage.length) {
    throw new Error(errorMessage[0]);
  }
  if (errorMessage) {
    throw new Error(errorMessage);
  }
  if (error.message) {
    throw new Error(error.message);
  }

  // redirect("/error");
};
