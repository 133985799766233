import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { InputText } from './elements/inputText';
import { useForm, SubmitHandler } from "react-hook-form"
import { useResponseError } from './../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import { getUserData} from '../service/userService';
import { STORAGE_USER } from '../constants/storage';
import { passwordPatch } from './../service/authService';
import isStrongPassword from 'validator/es/lib/isStrongPassword';
import { useNavigate } from 'react-router-dom';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

type Inputs = {
  oldPassword: string,
  password: string,
  passwordConfirm: string,
}

const VendorPasswordSettings = () => {
  const theme = useTheme();
  const { responseError, setResponseError } = useResponseError(50000);
  const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem(STORAGE_USER) || ''));
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const res = await getUserData({});
        const post = res;
        setUserProfile(res);
      } catch (error: any) {
        console.error('error', error)
      }

    };

    getUserObj();

    return () => { };
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formatData = data;

    try {
      const res = await passwordPatch({
        data: formatData,
      })

      ReactGA.event('passwordPatch', {
        userID: userProfile?.id,
        email: userProfile?.email,
        firstName: userProfile?.firstName,
        lastName: userProfile?.lastName,
        phoneNumber: userProfile?.phoneNumber,
        city: userProfile?.city,
        state: userProfile?.state,
        zip: userProfile?.zip,
        roles: userProfile?.roles,
      });

    } catch (error: any) {
      setResponseError(error.message);
    }
    handleClickOpen();
  }

  return <>
    <Box
      component="form"
      sx={[{
        '& > :not(style)': { my: 0.5, width: '325px' },
        'display': 'flex',
        'flexDirection': 'column',
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          '& > :not(style)': { my: 0.5, width: '100%' },
        }
      }),
      ]}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      id="hook-form"
    >
      <InputText
        label="Old Password"
        variant="filled"
        type="password"
        inputProps={{
          ...register("oldPassword", {
            required: true,
            validate: {
              isValid: (value) => {
                return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
              }
            }
          })
        }}
        InputLabelProps={{ shrink: !!watch('oldPassword') }}
        error={!!errors['oldPassword']}
        helperText={errors['oldPassword'] ? errors['oldPassword'].message : ''}
      />
      <InputText
        label="New Password"
        variant="filled"
        type="password"
        inputProps={{
          ...register("password", {
            required: true,
            validate: {
              isValid: (value) => {
                return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
              }
            }
          })
        }}
        InputLabelProps={{ shrink: !!watch('password') }}
        error={!!errors['password']}
        helperText={errors['password'] ? errors['password'].message : ''}
      />
      <InputText
        label="Confirm New Password"
        variant="filled"
        type="password"
        inputProps={{
          ...register("passwordConfirm", {
            required: true,
            validate: {
              isValid: (value) => {
                return isStrongPassword(value) || 'Password must contain at least 8 characters, one uppercase, one symbol, one lowercase and one number'
              },
              isMatch: (val: string) => {
                if (watch('password') !== val) {
                  return "Your passwords do not match";
                }
              },
            }
          })
        }}
        InputLabelProps={{ shrink: !!watch('passwordConfirm') }}
        error={!!errors['passwordConfirm']}
        helperText={errors['passwordConfirm'] ? errors['passwordConfirm'].message : ''}
      />
    </Box>
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          px: '55px',
          paddingBottom: '35px',
          pt: '20px',
        }
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      }),
      ]}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseModal close={handleClose} />
      <DialogTitle sx={{
        fontWeight: '700',
        maxWidth: '300px',
        textAlign: 'center'
      }}>
        {responseError ? "Something went wrong" : `Change has been submitted successfully`}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box sx={{ textAlign: 'center', color: theme.palette.error.main }}>{responseError}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          maxWidth: '325px',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            padding: '7px 20px',
            borderRadius: '10px',
            width: '100%',
          }}
          variant="contained"
          onClick={() => {
            if (!responseError) {
              navigate('/vendor');
            }
            handleClose()
          }}
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default VendorPasswordSettings;

