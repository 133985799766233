import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogContentText, DialogTitle, DialogContent, Typography } from '@mui/material';
import { verifyAuth, resendEmail } from '../service/authService';
import { useResponseError } from '../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import { getUserData } from '../service/userService';
import { STORAGE_AUTH_TOKEN, STORAGE_USER_CONFIRM_TOKEN } from '../constants/storage';
import { getLoginNavigationPath } from "../utils/navigation";
import { useAuth } from '../hooks/useAuth';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";

const AccountConfirm = () => {
	const { login, setUser } = useAuth();
	const navigate = useNavigate();
	const theme = useTheme();
	const { responseError, setResponseError } = useResponseError();
	const [searchParams] = useSearchParams();
	const token = searchParams.get("token");
	const tokenCookies = localStorage.getItem(STORAGE_USER_CONFIRM_TOKEN) || '';

	const [isSuccessRequest, setIsSuccessRequest] = useState<boolean | null>(null);
	const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
	const [isModalResentOpened, setIsModalResentOpened] = useState<boolean>(false);

	useEffect(() => {
		const verifyAccount = async () => {
			setIsSuccessRequest(false);
			let successStep = 0;

			if (token) {
				const data = {
					token,
					tokenRegister: tokenCookies
				}
				localStorage.setItem(STORAGE_AUTH_TOKEN, tokenCookies);

				try {
					await verifyAuth({
						data
					});
					setIsModalOpened(true);
					setIsSuccessRequest(true);
					successStep = 1;

					const resUserData = await getUserData({
						token: tokenCookies
					});
					successStep = 2;

					if (resUserData) {
						setTimeout(async () => {
							login(tokenCookies);
							setUser(resUserData);

							// this situation is not likely to happen (to have inventory after registration),
							// so this is implemented just in case of future changes (e.g. inventory set during the reg)
							const pathToNavigate = await getLoginNavigationPath(resUserData.mode);
							navigate(pathToNavigate, {
								replace: true
							});
							window.location.reload();
						}, 1500);
					}
				} catch (error: any) {
					setResponseError(error?.message || "Something went wrong");
					if(successStep >= 1) {
						setTimeout(() => {
							navigate('../login', {
								replace: true
							});
						}, 1500);
					}
				}
			}
		};

		verifyAccount();

		return () => { };
	}, [token]);

	const handleSubmit = async () => {
		if (tokenCookies) {
			try {
				const data = {
					token: tokenCookies
				}

				const res = await resendEmail({ token: tokenCookies });
				ReactGA.event({
					category: 'Account',
					action: 'confirmAccount',
					label: 'Confirm Account',
				});
				setIsModalResentOpened(true);
			} catch (error: any) {
				setResponseError(error.message)
			}
		} else {
			setResponseError('Something is wrong with your confirm token');
		}
	}

	return <>
		<Dialog
			sx={[{
				'.MuiDialog-paper': {
					px: '55px',
					py: '10px',
					maxWidth: '440px',
					boxSizing: 'border-box',
					width: '100%'
				}
			}, (theme) => ({
				[theme.breakpoints.down('md')]: {
					'.MuiDialog-paper': {
						px: '15px',
					}
				}
			})]
			}
			open={isModalOpened}
			onClose={() => setIsModalOpened(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CloseModal close={() => setIsModalOpened(false)} />
			<DialogTitle sx={{
				textAlign: 'center'
			}}>
				<DialogContentText sx={{
					fontWeight: '400',
					color: 'black',
					mb: '5px'
				}}>
					{isSuccessRequest ? 'Verification was successful' : 'Verification was failed'}
				</DialogContentText>
			</DialogTitle>
		</Dialog>
		<Box sx={
			[{
				backgroundColor: 'white',
				py: '30px',
				px: '56px',
				maxWidth: '440px',
				boxSizing: 'border-box',
				margin: '0 auto',
				borderRadius: '10px',
			},
			(theme) => ({
				[theme.breakpoints.down('sm')]: {
					px: '15px',
				}
			}),
			]}>
			<Typography variant="h4" style={{
				fontWeight: '700',
				fontSize: '21px',
				color: '#000',
				textAlign: 'center',
				marginBottom: '18px',
			}}>
				Registration
			</Typography>

			<Box
				sx={{
					'& > :not(style)': { my: 0.5, width: '100%' },
					'display': 'flex',
					'flexDirection': 'column',
					alignItems: 'center',
				}}
			>
				<Typography variant="body1" style={{
					fontSize: '21px',
					color: '#000',
					textAlign: 'center',
					marginBottom: '10px',
				}}>
					We’ve sent you an email<br />
					to confirm your registration.
				</Typography>
				<Typography variant="body1" style={{
					fontSize: '14px',
					color: '#000',
					textAlign: 'center',
					marginBottom: '20px',
				}}>
					If you do not see the message in your inbox, please check spam.
				</Typography>
				<Box sx={{ textAlign: 'center', color: theme.palette.error.main }}>{responseError}</Box>
				<Box
					sx={{
						'display': 'flex',
						'justifyContent': 'center',
						maxWidth: '200px',
						'width': '100%',
					}}
				>
					<Button sx={{ py: '8px', borderRadius: '10px' }} onClick={handleSubmit} variant="outlined">Send email Again</Button>
				</Box>
			</Box>
		</Box>
		<Dialog
			sx={[{
				'.MuiDialog-paper': {
					px: '55px',
					py: '10px',
					pt: '20px',
					maxWidth: '440px',
					boxSizing: 'border-box',
					width: '100%'
				}
			}, (theme) => ({
				[theme.breakpoints.down('md')]: {
					'.MuiDialog-paper': {
						px: '15px',
					}
				}
			})]
			}
			open={isModalResentOpened}
			onClose={() => setIsModalResentOpened(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CloseModal close={() => setIsModalResentOpened(false)} />
			<DialogTitle sx={{
				fontWeight: '700',
				textAlign: 'center'
			}}>
				{responseError ? "Something went wrong" : `Request has been submitted successfully`}
			</DialogTitle>
			{!responseError && <DialogContent>
				<DialogContentText sx={{
					fontWeight: '400',
					color: '#008986',
					mb: '5px',
					textAlign: 'center'
				}}>
					Please check your email. If you do not see the message in your inbox, please check spam.
				</DialogContentText>
			</DialogContent>}
		</Dialog>
	</>;
};

export default AccountConfirm;
