import { useState } from "react";
import { STORAGE_USER } from "../constants/storage";

export const useLocalStorage = (keyName: any, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        if (keyName === STORAGE_USER) {
          return JSON.parse(value);
        }
        return value;
      } else {
        if (defaultValue) {
          window.localStorage.setItem(keyName, defaultValue);
        }
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: any) => {
    try {
      if (typeof newValue === "object") {
        window.localStorage.setItem(keyName, JSON.stringify(newValue));
      } else {
        window.localStorage.setItem(keyName, newValue);
      }
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};