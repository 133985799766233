import { Box, TextField, Tab, Tabs, Typography } from '@mui/material';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import VendorActive from './../components/vendorActive';
import { useState, useEffect } from 'react';
import VendorAllMatches from '../components/vendorAllMatches';
import VendorFavorite from '../components/vendorFavorite';
import VendorNotInterested from '../components/vendorNotInterested';
import { useDebounce } from '../hooks/useDebounce';

const Vendor = () => {
  const routeMatch = useRouteMatch(['/vendor', '/vendor/matches', '/vendor/favorites', '/vendor/not-interested']);
  const currentTab = routeMatch?.pattern?.path;
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean | null>(null);
  const location = useLocation();
  const [searchText, setSearchText] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [triggerFetch, setTriggerFetch] = useState<boolean>(false);
  const debouncedSearchValue = useDebounce(searchText, 1000, '');

  useEffect(() => {
    if (debouncedSearchValue) {
      setTriggerFetch(true);
    }
  }, [debouncedSearchValue]);

  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  if (location.pathname.includes('conversations')) {
    return (
      <Outlet />
    )
  }

  return <>
    <Box sx={{
      'display': 'flex',
      'justifyContent': 'space-between',
      flexWrap: 'wrap',
      pt: '60px',
      pb: '11px'
    }}>
      <Typography variant="h4" style={{ color: '#000' }}>
        Matches
      </Typography>
    </Box>
        
    {!isSubscriptionActive && typeof isSubscriptionActive === 'boolean' ?
       <Box sx={[{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: '10rem',
        textAlign: 'center'
      }]}>
        <Typography variant='h4'>
          Activate a subscription to view matches
        </Typography>
        <Link style={{ color: 'black', fontSize: '16px' }} to="/vendor/settings">Manage subscription</Link>
      </Box>
      : 
      <>
        <Tabs sx={{
          overflow: 'auto',
          marginLeft: '-20px'
        }} value={currentTab}>
          <Tab label="All" value="/vendor" to="/vendor" component={Link} />
          <Tab label="Matches" value="/vendor/matches" to="/vendor/matches" component={Link} />
          <Tab label="Favorites" value="/vendor/favorites" to="/vendor/favorites" component={Link} />
          <Tab label="Not Interested" value="/vendor/not-interested" to="/vendor/not-interested" component={Link} />
        </Tabs>

        <TextField
          InputProps={{
            endAdornment: isSearching ?
              <DandruffSvg setIsSearching={setIsSearching} setTriggerFetch={setTriggerFetch} />
              : <CloseSvg setSearchText={setSearchText} setTriggerFetch={setTriggerFetch} />
          }}
          value={searchText}
          id="outlined-size-small"
          size="small"
          sx={{
            'div': {
              borderRadius: '14px',
              paddingRight: '4px',
              width: '183px',
              height: '33px',
            },
            background: '#fff',
            borderRadius: '14px',
            width: '183px',
            mb: '30px'
          }}
          placeholder='Search'
          onChange={(e: any) => {
            setIsSearching(e?.currentTarget?.value === "" ? false : true);
            setSearchText(e?.currentTarget?.value)
          }}
          onFocus={() => setIsSearching(true)}
          onBlur={() => setIsSearching(false)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              setTriggerFetch(true);
            }
          }}
        />
        {currentTab === '/vendor' ?
          <VendorAllMatches
            searchText={searchText}
            triggerFetch={triggerFetch}
            setTriggerFetch={setTriggerFetch}
          />
          : currentTab === '/vendor/matches' ?
            <VendorActive
              searchText={searchText}
              triggerFetch={triggerFetch}
              setTriggerFetch={setTriggerFetch}
              setIsSubscriptionActive={setIsSubscriptionActive}
            />
          : currentTab === '/vendor/favorites' ?
            <VendorFavorite 
              searchText={searchText}
              triggerFetch={triggerFetch}
              setTriggerFetch={setTriggerFetch}
            />
          : currentTab === '/vendor/not-interested' ?
            <VendorNotInterested 
              searchText={searchText}
              triggerFetch={triggerFetch}
              setTriggerFetch={setTriggerFetch}
            />
          : null
        }
      </>
    }
    <Outlet />
  </>
};

export default Vendor;

const DandruffSvg = ({ setIsSearching, setTriggerFetch }: any) => {
  return (
    <svg
      style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
      onMouseDown={(e: any) => {
        e.stopPropagation();
        setIsSearching(true);
        setTriggerFetch(true);
      }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.4853" cy="13.4854" r="5.5" transform="rotate(-45 13.4853 13.4854)" stroke="#666666"/>
      <path d="M17.7285 17.728L21.9712 21.9707" stroke="#666666"/>
    </svg>  
  );
}
const CloseSvg = ({ setSearchText, setTriggerFetch }: any) => {
  return (
    <svg
      style={{ position: 'absolute', right: 0, cursor: 'pointer' }}
      onClick={() => {
        setSearchText("");
        setTriggerFetch(true);
      }}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 11L19 19" stroke="#666666"/>
      <path d="M11 19L19 11" stroke="#666666"/>
    </svg>  
  );
}