import { useAuth } from '../../hooks/useAuth';
import { Navigate, Outlet } from "react-router-dom";
import { Loader } from '../elements/Loader';
import { Session } from '@talkjs/react';
import { TSignature } from '../../types/types';

type TProps = {
  signature: TSignature | null;
  talkJsId: string;
  me: any;
  userType?: string;
}

export const ProtectedLayout = ({ signature, talkJsId, me }: TProps) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/account/login" />;
  }

  // if ( (!signature || user?.mode !== signature?.mode) && user?.mode !== 'ADMIN' && user?.mode) {
  //   return <Loader />
  // }

  return (
    <Session signature={signature?.code || undefined} appId={talkJsId} syncUser={me}>
      <Outlet />
    </Session>
  )
};