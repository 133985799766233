import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { Link, Outlet, matchPath, useLocation } from 'react-router-dom';
import BuyerActive from '../components/buyerActive';
import { useNavigate } from 'react-router-dom';

const Buyer = () => {
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(['/buyer', '/buyer/archived']); // '/buyer/:id'
  const currentTab = routeMatch?.pattern?.path;
  const location = useLocation();
  
  function useRouteMatch(patterns: readonly string[]) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
      const pattern = patterns[i];
      const possibleMatch = matchPath(pattern, pathname);
      if (possibleMatch !== null) {
        return possibleMatch;
      }
    }

    return null;
  }

  if (location.pathname.includes('conversations')) {
    return (
      <Outlet />
    )
  }

  return <>
    <Box sx={[{
      'display': 'flex',
      'justifyContent': 'space-between',
      flexWrap: 'wrap',
      pt: '60px',
      pb: '11px'
    },
    (theme) => ({
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      }
    }),
    ]}>
      <Typography variant="h4" style={{ color: '#000' }}>
        My requests
      </Typography>

      <Box sx={[{
        'ml': 'auto',
        p: '10px',
        borderRadius: '10px',
        background: '#fff',
        boxShadow: "0px 10px 10px 0px #0089861A"
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          background: 'transparent',
          boxShadow: 'none',
          ml: 0,
          my: '10px',
          mt: '30px',
          px: 0
        }
      }),
      ]}>
        <Button onClick={() => navigate('/buyer/create')} variant="contained">create request</Button>
      </Box>
    </Box>

    <Tabs
      sx={[{
        '.MuiTabs-flexContainer': {
          pl: 0
        }
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          '.MuiTabs-flexContainer': {
            pl: 0
          }
        }
      }),
      ]}
      value={currentTab}>
      <Tab label="Active" value="/buyer" to="/buyer" component={Link} />
      <Tab label="Archived" value="/buyer/archived" to="/buyer/archived" component={Link} />
    </Tabs>

    {currentTab === '/buyer' ? <BuyerActive /> : null}

    <Outlet />
  </>;
};

export default Buyer;