import { useState, useCallback } from "react";

export const useResponseError = (time?: number) => {
  const [responseError, setResponseError] = useState<string | null>(null);

  const mSeconds = time ? time : 10000;
  
  return {
    responseError,
    setResponseError: useCallback((errorMessage: string) => {
      setResponseError(errorMessage);
      setTimeout(() => setResponseError(null), mSeconds);
    }, []),
  };
};
