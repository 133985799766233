import { Box, Button, Typography } from '@mui/material';
import { TAccountCall, TAccountDictio } from '../../types/types';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

type AccountCallBlockProps = {
		type: TAccountCall | string,
}

type NavItem = {
	url: string;
	title: string;
	btnTitle: string;
};

const AccountCallBlock = ({type}:AccountCallBlockProps) => {
		const navigate = useNavigate();
		const theme = useTheme();
		const getNavigateType = (value: string) => {

			const navigateDictionary: { [key: string]: NavItem } = {
				'login': {
						url: '../account/login',
						title: 'Already have an account?',
						btnTitle: 'Login'
				},
				'registration': {
						url: '../account/register',
						title: 'No account?',
						btnTitle: 'Register'
				},
				'back-to-login': {
						url: '../account/login',
						title: 'Back to login?',
						btnTitle: 'Login'
				},
			};
	
			return navigateDictionary[value] || navigateDictionary['/account/login'];
		}

		const currentType = getNavigateType(type) || getNavigateType('login');

		return <>
				<Box sx={[
				{ 
					'display': 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					'maxWidth': '340px',
					width: '100%',
					py: '20px',
					margin: '0 auto',			
				},
				(theme) => ({
					[theme.breakpoints.down('sm')]: { 
						'maxWidth': '100%',
					}
				}),
			]}>
					<Typography variant="body1" style={{ color: '#000', marginBottom: '20px' }}>
						{currentType.title}
					</Typography> 

					<Box>
						<Button sx={{py: '8px', borderRadius: '10px', color: theme.palette.primary.main }} onClick={() => navigate(currentType.url, { replace: true })} variant="outlined">{currentType.btnTitle}</Button>
					</Box>
				</Box>
		</>;
};

export default AccountCallBlock;