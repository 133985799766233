import { createContext, useContext, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { STORAGE_AUTH_TOKEN, STORAGE_USER } from "../constants/storage";
import { useCookies } from 'react-cookie';


interface AuthContextType {
  user: any;
  isVendor: boolean;
  isBuyer: boolean;
  isAdmin: boolean;
  userType: string;
  setUser: (user: object) => void;
  login: (accessToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children, storageValue }:any) => {
  const location = useLocation();
  const [user, setUser] = useLocalStorage(STORAGE_USER, (storageValue || null) );
  const [accessToken, setAccessToken] = useLocalStorage(STORAGE_AUTH_TOKEN, (storageValue || null) );
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["userType"]);

  useEffect(() => {
    setUser(
      JSON.parse(window.localStorage.getItem(STORAGE_USER) || null as any)
    );
  }, [location, location?.pathname]);

  const login = async (data:any) => {
    setAccessToken(data);
  };

  const logout = () => {
    setUser(null);
    setAccessToken(null);
    localStorage.clear();
    sessionStorage.clear();
    removeCookie('userType', { path: '/' });
    navigate("/account/login", { replace: true });
    window.location.reload();
  };

  const value = useMemo(
    () => ({
      user,
      accessToken,
      isVendor: user?.roles?.includes('VENDOR'),
      isBuyer: user?.roles?.includes('BUYER'),
      isAdmin: user?.roles?.includes('ADMIN'),
      userType: user?.mode,
      setUser,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};