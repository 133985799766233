import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TSettings, TUserProfile } from '../types/types';
import { Outlet, Link, useLocation } from 'react-router-dom';

const VendorSettings = () => {
	const location = useLocation();
	const settingsOptions: TSettings[] = [
		'GENERAL',
		'PROFILE',
		'PASSWORD',
	];

	const settingsTypeOptions: any = {
		'GENERAL': 'general',
		'PROFILE': 'profile',
		'PASSWORD': 'password',
	};

	const selectedSettingOption = settingsOptions.find(item => location.pathname?.includes(item.toLowerCase())) || "GENERAL";
	const showSubmitBtn = location.pathname.includes('profile') || location.pathname.includes('password');

	const renderSettingsOptions = () => {
		return settingsOptions.map((item: TSettings, i) => {
			return (<Box key={i} sx={{
				'mr': '25px',
				'borderRadius': '10px',
			}}>
				<Button component={Link} to={settingsTypeOptions[item]} sx={
					{
						'py': '6px',
						'px': '10px',
						'color': 'black',
						'backgroundColor': item === selectedSettingOption ? 'white' : 'transparent',
						'borderRadius': '10px',
						'&.MuiButton-text': {
							'color': 'black',
						}
					}
				} variant="text">{item}</Button>
			</Box>)
		})
	}

	return <>
		<Box sx={{
			marginBottom: '90px'
		}}>
			<Box sx={{
				'display': 'flex',
				'alignItems': 'center',
			}}>
				<h1>Settings</h1>
				{showSubmitBtn &&
					<Box sx={[
						{
							'ml': 'auto',
							'display': 'flex',
							'alignItems': 'center',
							boxShadow: '0px 10px 10px rgba(0, 137, 134, 0.10)',
						},
						(theme) => ({
							[theme.breakpoints.down('sm')]: {
								display: 'none',
							}
						}),
					]}>
						<Box sx={{ 'ml': 'auto', p: '10px', backgroundColor: 'white', borderRadius: '10px' }}>
							<Button type="submit" form="hook-form" sx={{ borderRadius: '10px' }} variant="contained">Save</Button>
						</Box>
					</Box>}
			</Box>
			<Box sx={{
				'display': 'flex',
				'alignItems': 'center',
				'mb': '20px',
			}}>
				{renderSettingsOptions()}
			</Box>
			<Outlet />
			{showSubmitBtn &&
				<Box sx={[
					{
						display: 'none'
					},
					(theme) => ({
						[theme.breakpoints.down('sm')]: {
							display: 'block',
							maxWidth: '335px',
							width: '100%',
							margin: '0 auto',
							marginTop: '20px'
						}
					}),
				]}>
					<Button type="submit" form="hook-form" sx={{ borderRadius: '10px', width: '100%' }} variant="contained">Save</Button>
				</Box>}
		</Box>
	</>;
};

export default VendorSettings;

