import { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation, Link } from 'react-router-dom';
import { blogList } from '../data/mock-blog-list';
import { Box, Button, Container, Typography, Tooltip } from '@mui/material';
import car from '../assets/car.png';
import AccountCallBlock from './blocks/accountCallBlock';
import { useTheme } from '@mui/material/styles';
import { useCookies } from 'react-cookie';

const Account = () => {
  const location = useLocation();
  const theme = useTheme();
  const [route, setRoute] = useState('/account/terms-of-use');
  const [cookie] = useCookies(["userType"]);
  const userType = cookie.userType;

  useEffect(() => {
    if (location.pathname.includes('/account')) {
      setRoute('/account/terms-of-use');
    } else {
      setRoute('/terms-of-use');
    }
  }, [location.pathname]);

  const getNavigateType = (value: string) => {
    const navigateDictionary: { [key: string]: string } = {
      '/account/login': 'registration',
      '/account/register': 'login',
      '/account/recovery': 'back-to-login',
      '/account/confirm': 'login',
      '/account/request-reset': 'back-to-login',
      '/account/reset': 'back-to-login',
      '/account/reset-step2': 'none',
    }

    return navigateDictionary[value] || navigateDictionary['/account/login'];
  }

  return <>
    <Box sx={{
      backgroundImage: `url(${car})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "50% 50%",
      maxWidth: '100%',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: '-1',
    }}></Box>
    <Container sx={[
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          pb: '50px'
        }
      }),
    ]} maxWidth="lg">
      <Box sx={[
        {
          display: 'flex',
          justifyContent: 'space-between',
          pt: '40px'
        },
      ]}>
        <Box sx={[
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              'margin': '0 auto',
              marginBottom: '40px',
            }
          }),
        ]}>
          <Tooltip title="Home">
            <Link
              style={{ textDecoration: 'none' }}
              to={'/'}
            >
              {
                userType === 'VENDOR' ?
                  <svg xmlns="http://www.w3.org/2000/svg" width="172" height="101" viewBox="0 0 172 101" fill="none">
                    <path d="M32.6945 53.0595C31.5641 50.6159 29.8021 48.0394 27.4749 45.1968C22.5544 39.3122 23.2526 31.7487 27.425 25.2159C34.6893 13.7792 54.4874 6.16583 58.1777 15.907C60.987 23.3042 54.4874 33.5773 45.6938 34.7243C45.461 34.7575 45.2117 34.6578 45.0621 34.4749L41.2886 29.5379C40.9229 29.0724 41.3053 28.4075 41.9037 28.474C47.1067 29.0558 53.3902 23.404 53.3071 18.5168C53.1908 11.1195 40.8066 15.641 35.4706 22.8388C27.9902 32.8126 32.8441 40.1268 38.3796 44.9308C43.9151 50.2502 46.2091 56.4673 45.3945 62.6178C42.6351 83.38 5.08357 94.5009 2.30752 74.4202C1.07741 65.7928 8.20871 57.4148 16.886 56.7332C17.5343 56.6834 17.8667 57.4813 17.368 57.9135C8.59105 65.7097 11.3671 75.7833 22.1887 73.1568C29.6192 71.4114 37.5817 63.1996 32.6945 53.0595Z" fill="#008986" />
                    <path d="M48.6358 63.6151C54.7199 51.0315 55.684 49.4357 55.7672 48.4715C55.7838 48.1723 55.9999 47.9396 56.2825 47.8731L60.8871 47.0087C61.4522 46.909 61.8678 47.524 61.552 48.0061C61.3858 48.272 61.2195 48.5546 61.0533 48.8705L55.4513 59.6422C53.4565 63.4489 53.9552 65.1943 55.4181 64.9616C56.9474 64.7954 59.4408 62.6011 61.4855 58.6781L63.5468 54.9711C65.0927 52.3946 66.755 49.2196 67.4199 47.4908C67.636 46.9422 67.7524 46.51 67.7856 46.1775C67.8189 45.8949 68.035 45.6622 68.3176 45.6123L73.5372 44.6316C74.1357 44.5152 74.5346 45.1968 74.1523 45.6788C73.4541 46.5599 72.8557 47.657 72.091 49.0201L67.4532 57.3815C65.6246 60.7061 66.5389 62.8671 68.6002 62.4848C71.1269 61.903 72.7061 59.5591 74.0359 57.4812C74.1523 57.2984 74.1689 57.0823 74.0858 56.8828C72.4733 52.7603 76.5294 43.9999 80.868 43.1688C82.6134 42.7864 83.5111 47.4908 79.0893 53.0927C79.0395 53.1426 79.0062 53.2091 78.9896 53.2756C77.3938 57.7638 80.9345 59.6921 83.6773 55.4532C83.8602 55.1706 84.2425 55.0709 84.5417 55.2371L85.0404 55.5197C85.3562 55.6859 85.456 56.0849 85.2897 56.4007C82.1812 62.0193 77.6597 64.0972 75.6816 61.3877C75.3824 60.9721 74.7673 61.0386 74.5512 61.504C71.343 68.8348 62.1005 74.3703 60.4881 67.4551C60.3551 66.9065 59.657 66.7569 59.3411 67.2057C57.363 69.8987 54.7864 72.3256 51.9771 72.9074C47.1564 73.855 46.0759 68.7849 48.6358 63.6151Z" fill="#008986" />
                    <path d="M83.7611 52.9598C86.3211 47.6737 91.3911 41.0577 96.378 40.293C98.0237 40.1101 99.3536 40.6587 100.002 41.8389C100.268 42.321 100.966 42.2878 101.182 41.7891C101.481 41.0743 101.714 40.3595 101.747 39.8608C101.764 39.5616 101.98 39.3122 102.279 39.2458L106.9 38.3813C107.466 38.2816 107.881 38.9133 107.549 39.3954C106.069 41.4899 104.058 45.4794 102.429 49.07C98.4559 57.8802 103.842 58.2293 108.679 50.7157C108.895 50.3832 109.377 50.3167 109.66 50.5827L110.075 50.9816C110.292 51.1811 110.341 51.5302 110.192 51.7795C105.654 59.3763 100.135 64.3632 96.9598 64.4463C95.4305 64.5295 94.4664 63.3991 94.4996 60.8225C94.5163 60.1576 93.6352 59.9082 93.3028 60.4734C91.4576 63.5321 89.147 65.959 86.8696 66.3912C82.2318 67.2723 80.1207 60.2906 83.7611 52.9598ZM97.4253 49.7682C99.3369 45.895 99.2704 44.0332 97.6081 43.2519C97.5749 43.2353 97.525 43.2187 97.4918 43.202C94.8487 42.5205 91.8233 46.3771 89.7787 50.0009C87.2021 54.4392 86.1548 59.0937 89.147 58.5285C92.0228 57.9966 95.4305 53.7411 97.4253 49.7682Z" fill="#008986" />
                    <path d="M111.522 41.174C112.12 39.7776 112.752 38.3647 112.851 37.6665C112.885 37.3839 113.101 37.1678 113.367 37.1013L118.802 36.0873C119.334 35.9876 119.733 36.5361 119.501 37.0182L119.467 37.0847C119.151 37.6998 119.916 38.3148 120.448 37.8826C122.942 35.8879 125.618 34.5414 127.646 34.4915C134.345 34.3087 132.832 43.7339 129.292 49.8845C128.992 50.3998 129.491 51.0315 130.056 50.8154C132.018 50.1172 133.348 48.3718 134.711 45.8285C134.96 45.3464 135.675 45.3796 135.874 45.8783L136.124 46.4768C136.207 46.6596 136.19 46.8757 136.09 47.0586C133.148 52.0455 130.655 53.7743 127.38 54.4558C127.23 54.4891 127.081 54.5722 126.998 54.7052C122.094 61.0884 112.137 62.7341 110.64 57.7804C110.474 57.2153 109.693 57.1321 109.427 57.6807C106.618 63.6151 105.454 67.0727 103.775 72.2259C103.709 72.4586 103.509 72.6249 103.276 72.6581L97.7742 72.6914C97.2589 72.7911 96.8433 72.2591 97.0594 71.7937C102.445 60.0246 108.064 49.0201 111.522 41.174ZM126.582 38.4478C122.443 38.481 117.971 42.8197 114.148 49.07C113.815 49.6185 114.381 50.2668 114.979 50.0008C117.872 48.6876 121.578 49.2694 123.374 50.2336C123.64 50.3832 123.956 50.3167 124.172 50.1006C128.244 45.5957 129.458 39.0462 126.732 38.4644C126.682 38.4478 126.632 38.4478 126.582 38.4478ZM113.915 54.5057C113.616 56.3841 118.686 56.4007 121.412 53.5748C121.728 53.259 121.612 52.727 121.213 52.5275C118.487 51.3141 114.298 51.6798 113.915 54.5057Z" fill="#008986" />
                    <path d="M155.323 44.2326L158.365 35.9709C158.548 35.4722 158.099 34.9901 157.584 35.1231L155.506 35.6883C154.974 35.8379 154.525 35.2894 154.741 34.7907L155.273 33.5938C155.356 33.411 155.506 33.278 155.689 33.2281L159.329 32.2473C159.529 32.1975 159.695 32.0312 159.778 31.8318L164.632 17.8351C164.865 17.2034 165.413 15.7905 165.347 14.9261C165.33 14.6269 165.513 14.3443 165.812 14.2612L170.4 13.0311C170.899 12.8981 171.348 13.3801 171.182 13.8788L165.663 29.5212C165.497 30.0198 165.945 30.5019 166.444 30.3689L168.771 29.7373C169.303 29.5876 169.752 30.1362 169.536 30.6349L169.004 31.8318C168.921 32.0146 168.771 32.1476 168.588 32.1975L164.832 33.2115C164.632 33.2613 164.466 33.4109 164.399 33.6104L160.875 42.9526C157.601 51.6465 165.114 49.7847 168.904 42.404C169.104 42.0051 169.652 41.922 169.968 42.2544L170.367 42.6866C170.533 42.8695 170.583 43.1355 170.483 43.3682C166.693 52.3613 161.341 57.0157 157.584 57.3981C153.644 57.797 151.982 53.2922 155.323 44.2326Z" fill="#008986" />
                    <path d="M156.686 3.45628L151.55 1.7441C151.184 1.59449 150.835 1.82721 150.685 2.19292C147.178 10.5377 133.813 5.70039 124.837 8.40996C116.791 10.8203 111.654 16.3225 111.804 23.7863C111.937 31.167 118.337 32.4968 122.775 31.5327C126 30.8345 129.607 27.9088 132.3 22.6559C132.583 22.0907 131.984 21.5089 131.436 21.7915C126.516 24.3515 120.864 22.0409 123.058 18.1178C127.097 10.8868 139.365 15.6244 147.594 12.3829C148.126 12.1834 148.524 12.6655 148.425 13.214C145.981 27.1608 141.111 65.1113 117.689 73.2898C94.0506 81.5515 80.3698 65.6598 29.0876 90.0791C22.1059 93.4038 10.3867 101.383 13.6282 99.8868C42.6853 86.4553 92.0559 100.502 122.011 86.2558C148.94 73.4561 154.409 21.1765 157.069 4.27081C157.102 3.92172 157.002 3.58926 156.686 3.45628Z" fill="#008986" />
                    <path d="M31.0324 51.3972C29.902 48.9536 28.14 46.377 25.8128 43.5345C20.8923 37.6499 21.5905 30.0864 25.7629 23.5535C33.0272 12.1168 52.8253 4.50347 56.5156 14.2446C59.3249 21.6419 52.8253 31.9149 44.0316 33.0619C43.7989 33.0952 43.5496 32.9954 43.4 32.8126L39.6265 27.8755C39.2608 27.4101 39.6432 26.7452 40.2416 26.8116C45.4446 27.3935 51.7281 21.7416 51.645 16.8544C51.5286 9.45715 39.1445 13.9786 33.8085 21.1764C26.3281 31.1503 31.182 38.4644 36.7175 43.2685C42.253 48.5879 44.547 54.8049 43.7324 60.9554C40.973 81.7177 3.42146 92.8385 0.645406 72.7578C-0.584702 64.1305 6.5466 55.7524 15.2238 55.0709C15.8721 55.021 16.2046 55.8189 15.7059 56.2511C6.92894 64.0473 9.70499 74.1209 20.5266 71.4945C27.9571 69.7491 35.9196 61.5373 31.0324 51.3972Z" fill="#FED701" />
                    <path d="M46.9737 61.9528C53.0578 49.3691 54.0219 47.7733 54.105 46.8092C54.1217 46.51 54.3378 46.2772 54.6204 46.2107L59.225 45.3463C59.7901 45.2466 60.2057 45.8616 59.8899 46.3437C59.7236 46.6097 59.5574 46.8923 59.3912 47.2081L53.7892 57.9799C51.7944 61.7866 52.2931 63.532 53.756 63.2993C55.2853 63.133 57.7787 60.9388 59.8234 57.0157L61.8846 53.3088C63.4306 50.7322 65.0929 47.5572 65.7578 45.8284C65.9739 45.2798 66.0903 44.8476 66.1235 44.5152C66.1568 44.2326 66.3729 43.9999 66.6555 43.95L71.8751 42.9692C72.4735 42.8529 72.8725 43.5344 72.4902 44.0165C71.792 44.8975 71.1936 45.9946 70.4289 47.3577L65.7911 55.7191C63.9625 59.0437 64.8768 61.2048 66.9381 60.8224C69.4648 60.2406 71.044 57.8968 72.3738 55.8189C72.4902 55.636 72.5068 55.4199 72.4237 55.2204C70.8112 51.0979 74.8673 42.3376 79.2059 41.5064C80.9513 41.1241 81.849 45.8284 77.4272 51.4304C77.3774 51.4802 77.3441 51.5467 77.3275 51.6132C75.7317 56.1015 79.2724 58.0297 82.0152 53.7909C82.198 53.5083 82.5804 53.4085 82.8796 53.5748L83.3783 53.8573C83.6941 54.0236 83.7939 54.4225 83.6276 54.7384C80.5191 60.357 75.9976 62.4349 74.0195 59.7253C73.7203 59.3097 73.1052 59.3762 72.8891 59.8417C69.6809 67.1724 60.4384 72.7079 58.826 65.7927C58.693 65.2442 57.9948 65.0946 57.679 65.5434C55.7009 68.2363 53.1243 70.6633 50.315 71.2451C45.4943 72.1926 44.4138 67.1226 46.9737 61.9528Z" fill="#FED701" />
                    <path d="M82.099 51.2974C84.659 46.0113 89.729 39.3953 94.7159 38.6306C96.3616 38.4478 97.6915 38.9964 98.3398 40.1766C98.6057 40.6587 99.3039 40.6254 99.52 40.1267C99.8192 39.4119 100.052 38.6971 100.085 38.1984C100.102 37.8992 100.318 37.6499 100.617 37.5834L105.238 36.719C105.804 36.6193 106.219 37.2509 105.887 37.733C104.407 39.8275 102.396 43.817 100.767 47.4076C96.7938 56.2179 102.18 56.567 107.017 49.0533C107.233 48.7209 107.715 48.6544 107.998 48.9203L108.413 49.3193C108.629 49.5188 108.679 49.8678 108.53 50.1172C103.992 57.7139 98.4727 62.7009 95.2977 62.784C93.7684 62.8671 92.8043 61.7367 92.8375 59.1602C92.8541 58.4952 91.9731 58.2459 91.6407 58.8111C89.7955 61.8697 87.4849 64.2967 85.2075 64.7289C80.5697 65.6099 78.4585 58.6282 82.099 51.2974ZM95.7632 48.1058C97.6748 44.2326 97.6083 42.3708 95.946 41.5896C95.9128 41.5729 95.8629 41.5563 95.8297 41.5397C93.1866 40.8581 90.1612 44.7147 88.1166 48.3385C85.54 52.7769 84.4927 57.4314 87.4849 56.8662C90.3607 56.3342 93.7684 52.0787 95.7632 48.1058Z" fill="#FED701" />
                    <path d="M109.859 39.5116C110.458 38.1153 111.09 36.7023 111.189 36.0042C111.222 35.7216 111.439 35.5055 111.705 35.439L117.14 34.425C117.672 34.3252 118.071 34.8738 117.838 35.3559L117.805 35.4224C117.489 36.0374 118.254 36.6525 118.786 36.2203C121.279 34.2255 123.956 32.879 125.984 32.8292C132.683 32.6463 131.17 42.0716 127.629 48.2221C127.33 48.7374 127.829 49.3691 128.394 49.153C130.356 48.4549 131.686 46.7094 133.049 44.1661C133.298 43.684 134.013 43.7173 134.212 44.216L134.462 44.8144C134.545 44.9973 134.528 45.2134 134.428 45.3962C131.486 50.3831 128.993 52.1119 125.718 52.7935C125.568 52.8267 125.419 52.9098 125.335 53.0428C120.432 59.4261 110.474 61.0718 108.978 56.1181C108.812 55.5529 108.031 55.4698 107.765 56.0183C104.956 61.9528 103.792 65.4104 102.113 70.5635C102.047 70.7963 101.847 70.9625 101.614 70.9958L96.1121 71.029C95.5968 71.1287 95.1812 70.5968 95.3973 70.1313C100.783 58.3622 106.402 47.3577 109.859 39.5116ZM124.92 36.7855C120.781 36.8187 116.309 41.1573 112.486 47.4076C112.153 47.9562 112.719 48.6045 113.317 48.3385C116.209 47.0253 119.916 47.6071 121.712 48.5712C121.978 48.7208 122.293 48.6543 122.51 48.4382C126.582 43.9334 127.796 37.3839 125.07 36.8021C125.02 36.7855 124.97 36.7855 124.92 36.7855ZM112.253 52.8433C111.954 54.7218 117.024 54.7384 119.75 51.9125C120.066 51.5966 119.95 51.0647 119.551 50.8652C116.824 49.6517 112.635 50.0174 112.253 52.8433Z" fill="#FED701" />
                    <path d="M153.661 42.5703L156.703 34.3087C156.886 33.81 156.437 33.3279 155.922 33.4609L153.844 34.0261C153.312 34.1757 152.863 33.6271 153.079 33.1284L153.611 31.9316C153.694 31.7487 153.844 31.6157 154.027 31.5659L157.667 30.5851C157.867 30.5352 158.033 30.369 158.116 30.1695L162.97 16.1729C163.203 15.5412 163.751 14.1283 163.685 13.2639C163.668 12.9646 163.851 12.682 164.15 12.5989L168.738 11.3688C169.237 11.2358 169.686 11.7179 169.519 12.2166L164.001 27.8589C163.834 28.3576 164.283 28.8397 164.782 28.7067L167.109 28.075C167.641 27.9254 168.09 28.474 167.874 28.9727L167.342 30.1695C167.259 30.3524 167.109 30.4854 166.926 30.5352L163.169 31.5492C162.97 31.5991 162.804 31.7487 162.737 31.9482L159.213 41.2904C155.938 49.9842 163.452 48.1225 167.242 40.7418C167.442 40.3429 167.99 40.2597 168.306 40.5922L168.705 41.0244C168.871 41.2072 168.921 41.4732 168.821 41.7059C165.031 50.699 159.679 55.3535 155.922 55.7358C151.982 56.1348 150.32 51.6299 153.661 42.5703Z" fill="#FED701" />
                    <path d="M155.024 1.79392L149.888 0.0817463C149.522 -0.0678614 149.173 0.164861 149.023 0.530569C145.516 8.87536 132.151 4.03804 123.174 6.7476C115.129 9.15795 109.992 14.6602 110.142 22.124C110.275 29.5046 116.675 30.8344 121.113 29.8703C124.338 29.1721 127.945 26.2465 130.638 20.9936C130.921 20.4284 130.322 19.8466 129.774 20.1292C124.853 22.6891 119.202 20.3785 121.396 16.4555C125.435 9.22444 137.703 13.962 145.931 10.7205C146.463 10.521 146.862 11.0031 146.763 11.5517C144.319 25.4984 139.448 63.4489 116.027 71.6275C92.3885 79.8892 78.7077 63.9975 27.4255 88.4168C20.4438 91.7414 8.72456 99.7205 11.9661 98.2244C41.0232 84.793 90.3938 98.8395 120.349 84.5935C147.278 71.7937 152.747 19.5141 155.407 2.60845C155.44 2.25937 155.34 1.92691 155.024 1.79392Z" fill="#FED701" />
                    <path d="M69.2486 79.6563L73.8199 79.4402L73.4708 81.0859L70.6449 81.2189L70.2958 82.8313L73.1217 82.6983L72.7726 84.3274L69.9467 84.4604L69.0989 88.4665L67.3535 88.5496L69.2486 79.6563Z" fill="#008986" />
                    <path d="M79.3225 79.1744L81.1012 79.0913L82.7468 87.835L80.9349 87.9182L80.6025 86.1229L76.8457 86.3057L75.6987 88.1841L73.8701 88.2672L79.3225 79.1744ZM79.7214 81.485L77.843 84.6102L80.3033 84.4938L79.7214 81.485Z" fill="#008986" />
                    <path d="M91.7228 79.7728L90.1934 80.9365C89.861 80.338 89.462 80.0554 88.9966 80.0887C88.7638 80.1053 88.5644 80.1718 88.3981 80.2882C88.2319 80.4045 88.1322 80.5541 88.0989 80.7037C88.0657 80.8533 88.0823 80.9863 88.1654 81.1193C88.2651 81.2855 88.6142 81.6512 89.2127 82.1998C89.7612 82.7151 90.0937 83.031 90.21 83.164C90.4926 83.4964 90.6755 83.8123 90.7586 84.1281C90.8417 84.4439 90.8417 84.7764 90.7586 85.1587C90.609 85.8901 90.2101 86.5052 89.595 87.0039C88.9799 87.5026 88.2485 87.7685 87.4008 87.8184C86.7525 87.8517 86.2039 87.7187 85.7883 87.4361C85.3727 87.1535 85.0569 86.688 84.8574 86.0231L86.5197 85.0922C86.8023 85.8569 87.2179 86.2226 87.7997 86.206C88.0989 86.1894 88.3649 86.0896 88.6142 85.9068C88.847 85.7239 88.9966 85.5244 89.0464 85.3083C89.0963 85.1088 89.0631 84.9094 88.9467 84.7099C88.8303 84.5104 88.5644 84.2278 88.1155 83.8289C87.2844 83.0808 86.7691 82.499 86.5696 82.0835C86.3701 81.6679 86.3203 81.2523 86.4034 80.8201C86.5364 80.205 86.8854 79.6565 87.4672 79.191C88.049 78.7256 88.6973 78.4762 89.4121 78.443C89.8776 78.4264 90.2932 78.5095 90.6589 78.6923C90.9747 78.8918 91.357 79.2409 91.7228 79.7728Z" fill="#008986" />
                    <path d="M94.0503 78.4596L99.1369 78.2102L98.7878 79.8891L97.1089 79.9723L95.563 87.2033L93.7843 87.2864L95.3302 80.0554L93.7012 80.1385L94.0503 78.4596Z" fill="#008986" />
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" width="172" height="101" viewBox="0 0 172 101" fill="none">
                    <path d="M32.6945 53.0595C31.5641 50.6159 29.8021 48.0393 27.4749 45.1968C22.5544 39.3122 23.2526 31.7487 27.425 25.2158C34.6893 13.7791 54.4874 6.16577 58.1777 15.9069C60.987 23.3042 54.4874 33.5772 45.6938 34.7242C45.461 34.7575 45.2117 34.6577 45.0621 34.4749L41.2886 29.5378C40.9229 29.0724 41.3053 28.4074 41.9037 28.4739C47.1067 29.0557 53.3902 23.4039 53.3071 18.5167C53.1908 11.1194 40.8066 15.6409 35.4706 22.8387C27.9902 32.8126 32.8441 40.1267 38.3796 44.9308C43.9151 50.2502 46.2091 56.4672 45.3945 62.6177C42.6351 83.38 5.08357 94.5008 2.30752 74.4201C1.07741 65.7927 8.20871 57.4147 16.886 56.7332C17.5343 56.6833 17.8667 57.4812 17.368 57.9134C8.59105 65.7096 11.3671 75.7832 22.1887 73.1568C29.6192 71.4114 37.5817 63.1995 32.6945 53.0595Z" fill="#FED701" />
                    <path d="M48.6358 63.6151C54.7199 51.0315 55.684 49.4357 55.7672 48.4715C55.7838 48.1723 55.9999 47.9396 56.2825 47.8731L60.8871 47.0087C61.4522 46.909 61.8678 47.524 61.552 48.0061C61.3858 48.272 61.2195 48.5546 61.0533 48.8705L55.4513 59.6422C53.4565 63.4489 53.9552 65.1943 55.4181 64.9616C56.9474 64.7954 59.4408 62.6011 61.4855 58.6781L63.5468 54.9711C65.0927 52.3946 66.755 49.2196 67.4199 47.4908C67.636 46.9422 67.7524 46.51 67.7856 46.1775C67.8189 45.8949 68.035 45.6622 68.3176 45.6123L73.5372 44.6316C74.1357 44.5152 74.5346 45.1968 74.1523 45.6788C73.4541 46.5599 72.8557 47.657 72.091 49.0201L67.4532 57.3815C65.6246 60.7061 66.5389 62.8671 68.6002 62.4848C71.1269 61.903 72.7061 59.5591 74.0359 57.4812C74.1523 57.2984 74.1689 57.0823 74.0858 56.8828C72.4733 52.7603 76.5294 43.9999 80.868 43.1688C82.6134 42.7864 83.5111 47.4908 79.0893 53.0927C79.0395 53.1426 79.0062 53.2091 78.9896 53.2756C77.3938 57.7638 80.9345 59.6921 83.6773 55.4532C83.8602 55.1706 84.2425 55.0709 84.5417 55.2371L85.0404 55.5197C85.3562 55.6859 85.456 56.0849 85.2897 56.4007C82.1812 62.0193 77.6597 64.0972 75.6816 61.3877C75.3824 60.9721 74.7673 61.0386 74.5512 61.504C71.343 68.8348 62.1005 74.3703 60.4881 67.4551C60.3551 66.9065 59.657 66.7569 59.3411 67.2057C57.363 69.8987 54.7864 72.3256 51.9771 72.9074C47.1564 73.855 46.0759 68.7849 48.6358 63.6151Z" fill="#FED701" />
                    <path d="M83.7611 52.9597C86.3211 47.6736 91.3911 41.0576 96.378 40.2929C98.0237 40.1101 99.3536 40.6586 100.002 41.8389C100.268 42.321 100.966 42.2877 101.182 41.789C101.481 41.0742 101.714 40.3594 101.747 39.8607C101.764 39.5615 101.98 39.3122 102.279 39.2457L106.9 38.3813C107.466 38.2815 107.881 38.9132 107.549 39.3953C106.069 41.4898 104.058 45.4793 102.429 49.0699C98.4559 57.8802 103.842 58.2292 108.679 50.7156C108.895 50.3832 109.377 50.3167 109.66 50.5826L110.075 50.9816C110.292 51.1811 110.341 51.5301 110.192 51.7795C105.654 59.3762 100.135 64.3632 96.9598 64.4463C95.4305 64.5294 94.4664 63.399 94.4996 60.8224C94.5163 60.1575 93.6352 59.9082 93.3028 60.4734C91.4576 63.532 89.147 65.959 86.8696 66.3912C82.2318 67.2722 80.1207 60.2905 83.7611 52.9597ZM97.4253 49.7681C99.3369 45.8949 99.2704 44.0331 97.6081 43.2518C97.5749 43.2352 97.525 43.2186 97.4918 43.202C94.8487 42.5204 91.8233 46.377 89.7787 50.0008C87.2021 54.4392 86.1548 59.0936 89.147 58.5285C92.0228 57.9965 95.4305 53.741 97.4253 49.7681Z" fill="#FED701" />
                    <path d="M111.522 41.174C112.12 39.7776 112.752 38.3647 112.851 37.6665C112.885 37.3839 113.101 37.1678 113.367 37.1013L118.802 36.0873C119.334 35.9876 119.733 36.5361 119.501 37.0182L119.467 37.0847C119.151 37.6998 119.916 38.3148 120.448 37.8826C122.942 35.8879 125.618 34.5414 127.646 34.4915C134.345 34.3087 132.832 43.7339 129.292 49.8845C128.992 50.3998 129.491 51.0315 130.056 50.8154C132.018 50.1172 133.348 48.3718 134.711 45.8285C134.96 45.3464 135.675 45.3796 135.874 45.8783L136.124 46.4768C136.207 46.6596 136.19 46.8757 136.09 47.0586C133.148 52.0455 130.655 53.7743 127.38 54.4558C127.23 54.4891 127.081 54.5722 126.998 54.7052C122.094 61.0884 112.137 62.7341 110.64 57.7804C110.474 57.2153 109.693 57.1321 109.427 57.6807C106.618 63.6151 105.454 67.0727 103.775 72.2259C103.709 72.4586 103.509 72.6249 103.276 72.6581L97.7742 72.6914C97.2589 72.7911 96.8433 72.2591 97.0594 71.7937C102.445 60.0246 108.064 49.0201 111.522 41.174ZM126.582 38.4478C122.443 38.481 117.971 42.8197 114.148 49.07C113.815 49.6185 114.381 50.2668 114.979 50.0008C117.872 48.6876 121.578 49.2694 123.374 50.2336C123.64 50.3832 123.956 50.3167 124.172 50.1006C128.244 45.5957 129.458 39.0462 126.732 38.4644C126.682 38.4478 126.632 38.4478 126.582 38.4478ZM113.915 54.5057C113.616 56.3841 118.686 56.4007 121.412 53.5748C121.728 53.259 121.612 52.727 121.213 52.5275C118.487 51.3141 114.298 51.6798 113.915 54.5057Z" fill="#FED701" />
                    <path d="M155.323 44.2326L158.365 35.971C158.548 35.4723 158.099 34.9902 157.584 35.1232L155.506 35.6884C154.974 35.838 154.525 35.2894 154.741 34.7907L155.273 33.5939C155.356 33.411 155.506 33.278 155.689 33.2282L159.329 32.2474C159.529 32.1975 159.695 32.0313 159.778 31.8318L164.632 17.8352C164.865 17.2035 165.413 15.7905 165.347 14.9261C165.33 14.6269 165.513 14.3443 165.812 14.2612L170.4 13.0311C170.899 12.8981 171.348 13.3802 171.182 13.8789L165.663 29.5212C165.497 30.0199 165.945 30.502 166.444 30.369L168.771 29.7373C169.303 29.5877 169.752 30.1363 169.536 30.635L169.004 31.8318C168.921 32.0147 168.771 32.1477 168.588 32.1975L164.832 33.2115C164.632 33.2614 164.466 33.411 164.399 33.6105L160.875 42.9527C157.601 51.6465 165.114 49.7847 168.904 42.4041C169.104 42.0051 169.652 41.922 169.968 42.2545L170.367 42.6867C170.533 42.8695 170.583 43.1355 170.483 43.3682C166.693 52.3613 161.341 57.0158 157.584 57.3981C153.644 57.7971 151.982 53.2922 155.323 44.2326Z" fill="#FED701" />
                    <path d="M156.686 3.45621L151.55 1.74404C151.184 1.59443 150.835 1.82715 150.685 2.19286C147.178 10.5376 133.813 5.70033 124.837 8.4099C116.791 10.8202 111.654 16.3225 111.804 23.7862C111.937 31.1669 118.337 32.4967 122.775 31.5326C126 30.8344 129.607 27.9088 132.3 22.6559C132.583 22.0907 131.984 21.5089 131.436 21.7915C126.516 24.3514 120.864 22.0408 123.058 18.1178C127.097 10.8867 139.365 15.6243 147.594 12.3828C148.126 12.1833 148.524 12.6654 148.425 13.214C145.981 27.1607 141.111 65.1112 117.689 73.2898C94.0506 81.5514 80.3698 65.6598 29.0876 90.0791C22.1059 93.4037 10.3867 101.383 13.6282 99.8867C42.6853 86.4552 92.0559 100.502 122.011 86.2558C148.94 73.456 154.409 21.1764 157.069 4.27075C157.102 3.92166 157.002 3.5892 156.686 3.45621Z" fill="#FED701" />
                    <path d="M31.0324 51.3972C29.902 48.9536 28.14 46.377 25.8128 43.5345C20.8923 37.6499 21.5905 30.0864 25.7629 23.5535C33.0272 12.1168 52.8253 4.50347 56.5156 14.2446C59.3249 21.6419 52.8253 31.9149 44.0316 33.0619C43.7989 33.0952 43.5496 32.9954 43.4 32.8126L39.6265 27.8755C39.2608 27.4101 39.6432 26.7452 40.2416 26.8116C45.4446 27.3935 51.7281 21.7416 51.645 16.8544C51.5286 9.45715 39.1445 13.9786 33.8085 21.1764C26.3281 31.1503 31.182 38.4644 36.7175 43.2685C42.253 48.5879 44.547 54.8049 43.7324 60.9554C40.973 81.7177 3.42146 92.8385 0.645406 72.7578C-0.584702 64.1305 6.5466 55.7524 15.2238 55.0709C15.8721 55.021 16.2046 55.8189 15.7059 56.2511C6.92894 64.0473 9.70499 74.1209 20.5266 71.4945C27.9571 69.7491 35.9196 61.5373 31.0324 51.3972Z" fill="#008986" />
                    <path d="M46.9737 61.9528C53.0578 49.3692 54.0219 47.7734 54.105 46.8092C54.1217 46.51 54.3378 46.2773 54.6204 46.2108L59.225 45.3464C59.7901 45.2467 60.2057 45.8617 59.8899 46.3438C59.7236 46.6097 59.5574 46.8923 59.3912 47.2082L53.7892 57.9799C51.7944 61.7866 52.2931 63.532 53.756 63.2993C55.2853 63.1331 57.7787 60.9388 59.8234 57.0158L61.8846 53.3088C63.4306 50.7323 65.0929 47.5573 65.7578 45.8285C65.9739 45.2799 66.0903 44.8477 66.1235 44.5152C66.1568 44.2326 66.3729 43.9999 66.6555 43.9501L71.8751 42.9693C72.4735 42.8529 72.8725 43.5345 72.4902 44.0165C71.792 44.8976 71.1936 45.9947 70.4289 47.3578L65.7911 55.7192C63.9625 59.0438 64.8768 61.2048 66.9381 60.8225C69.4648 60.2407 71.044 57.8968 72.3738 55.8189C72.4902 55.6361 72.5068 55.42 72.4237 55.2205C70.8112 51.098 74.8673 42.3376 79.2059 41.5065C80.9513 41.1241 81.849 45.8285 77.4272 51.4304C77.3774 51.4803 77.3441 51.5468 77.3275 51.6133C75.7317 56.1015 79.2724 58.0298 82.0152 53.7909C82.198 53.5083 82.5804 53.4086 82.8796 53.5748L83.3783 53.8574C83.6941 54.0236 83.7939 54.4226 83.6276 54.7384C80.5191 60.357 75.9976 62.4349 74.0195 59.7254C73.7203 59.3098 73.1052 59.3763 72.8891 59.8417C69.6809 67.1725 60.4384 72.708 58.826 65.7928C58.693 65.2442 57.9948 65.0946 57.679 65.5434C55.7009 68.2364 53.1243 70.6633 50.315 71.2452C45.4943 72.1927 44.4138 67.1226 46.9737 61.9528Z" fill="#008986" />
                    <path d="M82.099 51.2974C84.659 46.0113 89.729 39.3953 94.7159 38.6306C96.3616 38.4478 97.6915 38.9964 98.3398 40.1766C98.6057 40.6587 99.3039 40.6254 99.52 40.1267C99.8192 39.4119 100.052 38.6971 100.085 38.1984C100.102 37.8992 100.318 37.6499 100.617 37.5834L105.238 36.719C105.804 36.6193 106.219 37.2509 105.887 37.733C104.407 39.8275 102.396 43.817 100.767 47.4076C96.7938 56.2179 102.18 56.567 107.017 49.0533C107.233 48.7209 107.715 48.6544 107.998 48.9203L108.413 49.3193C108.629 49.5188 108.679 49.8678 108.53 50.1172C103.992 57.7139 98.4727 62.7009 95.2977 62.784C93.7684 62.8671 92.8043 61.7367 92.8375 59.1602C92.8541 58.4952 91.9731 58.2459 91.6407 58.8111C89.7955 61.8697 87.4849 64.2967 85.2075 64.7289C80.5697 65.6099 78.4585 58.6282 82.099 51.2974ZM95.7632 48.1058C97.6748 44.2326 97.6083 42.3708 95.946 41.5896C95.9128 41.5729 95.8629 41.5563 95.8297 41.5397C93.1866 40.8581 90.1612 44.7147 88.1166 48.3385C85.54 52.7769 84.4927 57.4314 87.4849 56.8662C90.3607 56.3342 93.7684 52.0787 95.7632 48.1058Z" fill="#008986" />
                    <path d="M109.859 39.5117C110.458 38.1154 111.09 36.7024 111.189 36.0042C111.222 35.7216 111.439 35.5055 111.705 35.439L117.14 34.425C117.672 34.3253 118.071 34.8739 117.838 35.3559L117.805 35.4224C117.489 36.0375 118.254 36.6525 118.786 36.2203C121.279 34.2256 123.956 32.8791 125.984 32.8292C132.683 32.6464 131.17 42.0717 127.629 48.2222C127.33 48.7375 127.829 49.3692 128.394 49.1531C130.356 48.4549 131.686 46.7095 133.049 44.1662C133.298 43.6841 134.013 43.7173 134.212 44.216L134.462 44.8145C134.545 44.9973 134.528 45.2134 134.428 45.3963C131.486 50.3832 128.993 52.112 125.718 52.7935C125.568 52.8268 125.419 52.9099 125.335 53.0429C120.432 59.4261 110.474 61.0718 108.978 56.1182C108.812 55.553 108.031 55.4698 107.765 56.0184C104.956 61.9529 103.792 65.4105 102.113 70.5636C102.047 70.7963 101.847 70.9626 101.614 70.9958L96.1121 71.0291C95.5968 71.1288 95.1812 70.5969 95.3973 70.1314C100.783 58.3623 106.402 47.3578 109.859 39.5117ZM124.92 36.7855C120.781 36.8188 116.309 41.1574 112.486 47.4077C112.153 47.9562 112.719 48.6045 113.317 48.3386C116.209 47.0253 119.916 47.6071 121.712 48.5713C121.978 48.7209 122.293 48.6544 122.51 48.4383C126.582 43.9334 127.796 37.3839 125.07 36.8021C125.02 36.7855 124.97 36.7855 124.92 36.7855ZM112.253 52.8434C111.954 54.7218 117.024 54.7384 119.75 51.9125C120.066 51.5967 119.95 51.0647 119.551 50.8653C116.824 49.6518 112.635 50.0175 112.253 52.8434Z" fill="#008986" />
                    <path d="M153.661 42.5703L156.703 34.3087C156.886 33.81 156.437 33.3279 155.922 33.4609L153.844 34.0261C153.312 34.1757 152.863 33.6271 153.079 33.1284L153.611 31.9316C153.694 31.7487 153.844 31.6157 154.027 31.5659L157.667 30.5851C157.867 30.5352 158.033 30.369 158.116 30.1695L162.97 16.1729C163.203 15.5412 163.751 14.1283 163.685 13.2639C163.668 12.9646 163.851 12.682 164.15 12.5989L168.738 11.3688C169.237 11.2358 169.686 11.7179 169.519 12.2166L164.001 27.8589C163.834 28.3576 164.283 28.8397 164.782 28.7067L167.109 28.075C167.641 27.9254 168.09 28.474 167.874 28.9727L167.342 30.1695C167.259 30.3524 167.109 30.4854 166.926 30.5352L163.169 31.5492C162.97 31.5991 162.804 31.7487 162.737 31.9482L159.213 41.2904C155.938 49.9842 163.452 48.1225 167.242 40.7418C167.442 40.3429 167.99 40.2597 168.306 40.5922L168.705 41.0244C168.871 41.2072 168.921 41.4732 168.821 41.7059C165.031 50.699 159.679 55.3535 155.922 55.7358C151.982 56.1348 150.32 51.6299 153.661 42.5703Z" fill="#008986" />
                    <path d="M155.024 1.79392L149.888 0.0817463C149.522 -0.0678614 149.173 0.164861 149.023 0.530569C145.516 8.87536 132.151 4.03804 123.174 6.7476C115.129 9.15795 109.992 14.6602 110.142 22.124C110.275 29.5046 116.675 30.8344 121.113 29.8703C124.338 29.1721 127.945 26.2465 130.638 20.9936C130.921 20.4284 130.322 19.8466 129.774 20.1292C124.853 22.6891 119.202 20.3785 121.396 16.4555C125.435 9.22444 137.703 13.962 145.931 10.7205C146.463 10.521 146.862 11.0031 146.763 11.5517C144.319 25.4984 139.448 63.4489 116.027 71.6275C92.3885 79.8892 78.7077 63.9975 27.4255 88.4168C20.4438 91.7414 8.72456 99.7205 11.9661 98.2244C41.0232 84.793 90.3938 98.8395 120.349 84.5935C147.278 71.7937 152.747 19.5141 155.407 2.60845C155.44 2.25937 155.34 1.92691 155.024 1.79392Z" fill="#008986" />
                    <path d="M69.2486 79.6565L73.8199 79.4404L73.4708 81.0861L70.6449 81.219L70.2958 82.8315L73.1217 82.6985L72.7726 84.3276L69.9467 84.4605L69.0989 88.4667L67.3535 88.5498L69.2486 79.6565Z" fill="#FED701" />
                    <path d="M79.3225 79.1744L81.1012 79.0912L82.7468 87.835L80.9349 87.9181L80.6025 86.1228L76.8457 86.3057L75.6987 88.1841L73.8701 88.2672L79.3225 79.1744ZM79.7214 81.485L77.843 84.6101L80.3033 84.4937L79.7214 81.485Z" fill="#FED701" />
                    <path d="M91.7228 79.7728L90.1934 80.9364C89.861 80.338 89.462 80.0554 88.9966 80.0886C88.7638 80.1052 88.5644 80.1717 88.3981 80.2881C88.2319 80.4045 88.1322 80.5541 88.0989 80.7037C88.0657 80.8533 88.0823 80.9863 88.1654 81.1192C88.2651 81.2855 88.6142 81.6512 89.2127 82.1997C89.7612 82.7151 90.0937 83.0309 90.21 83.1639C90.4926 83.4964 90.6755 83.8122 90.7586 84.128C90.8417 84.4439 90.8417 84.7763 90.7586 85.1587C90.609 85.8901 90.2101 86.5051 89.595 87.0038C88.9799 87.5025 88.2485 87.7685 87.4008 87.8184C86.7525 87.8516 86.2039 87.7186 85.7883 87.436C85.3727 87.1534 85.0569 86.688 84.8574 86.0231L86.5197 85.0922C86.8023 85.8568 87.2179 86.2225 87.7997 86.2059C88.0989 86.1893 88.3649 86.0896 88.6142 85.9067C88.847 85.7238 88.9966 85.5244 89.0464 85.3083C89.0963 85.1088 89.0631 84.9093 88.9467 84.7098C88.8303 84.5104 88.5644 84.2278 88.1155 83.8288C87.2844 83.0808 86.7691 82.499 86.5696 82.0834C86.3701 81.6678 86.3203 81.2522 86.4034 80.82C86.5364 80.205 86.8854 79.6564 87.4672 79.191C88.049 78.7255 88.6973 78.4762 89.4121 78.4429C89.8776 78.4263 90.2932 78.5094 90.6589 78.6923C90.9747 78.8918 91.357 79.2408 91.7228 79.7728Z" fill="#FED701" />
                    <path d="M94.0503 78.4596L99.1369 78.2102L98.7878 79.8891L97.1089 79.9723L95.563 87.2033L93.7843 87.2864L95.3302 80.0554L93.7012 80.1385L94.0503 78.4596Z" fill="#FED701" />
                  </svg>
              }
            </Link>
          </Tooltip>
        </Box>
        <Box sx={[{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'right'
        },
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            'display': 'none'
          }
        }),
        ]}>
          <Box>
            <a
              href='mailto:contact@swapitfast.com'
              style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold' }}
            >
              contact@swapitfast.com
            </a>
          </Box>
          <Box>
            <Link
              style={{ color: '#000', textDecoration: 'none' }}
              to={"https://swapitfast.com/terms-of-use"}
              target="_blank"
            >
              Terms of Use
            </Link>
          </Box>
        </Box>
      </Box>

      <Outlet />
      {getNavigateType(location.pathname) !== 'none' && location.pathname !== "/account/terms-of-use" ? <AccountCallBlock type={getNavigateType(location.pathname)} /> : ''}
      {location.pathname !== "/account/terms-of-use" && <Box sx={[{
        display: 'none',
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          marginTop: '40px',
          'display': 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }
      }),
      ]}>
        <Box>
          <a href='mailto:contact@swapitfast.com' style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold' }}>contact@swapitfast.com</a>
        </Box>
        <Box>
          <Link target="_blank" to={"https://swapitfast.com/terms-of-use"} style={{ color: '#000', textDecoration: 'none' }}>Terms of Use</Link>
        </Box>
      </Box>}
    </Container>
  </>;
};

export default Account;
