import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import VendorPost from './vendorPost';
import { useResponseError } from '../hooks/useResponseError';
import { getMatchingList } from '../service/matchingService';
import { isInventoryFilled } from '../service/inventoryService';
import { useTheme } from '@mui/material/styles';
import { Loader } from './elements/Loader';
import { useNavigate } from 'react-router-dom';

const VendorActive = ({ searchText, triggerFetch, setTriggerFetch, setIsSubscriptionActive }: any) => {
  const [posts, setPosts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { responseError, setResponseError } = useResponseError();
  const theme = useTheme();
  const lastPostRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [isInventoryExist, setIsInventoryExist] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const checkInventory = async () => {
    try {
      const hasInventory = await isInventoryFilled();
      setIsInventoryExist(hasInventory);
    } catch (error) {
      setIsInventoryExist(false);
    }
  }

  useEffect(() => {
    checkInventory();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - 75 &&
      !isLoading &&
      hasMore
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
    if (searchText) {
      setPageNumber(1);
      getPostsArr(1, searchText);
    } else {
      setPageNumber(1);
      getPostsArr(1, "");
    }
  }, [searchText]);

  const getPostsArr = useCallback(async (pageNumber: number, searchField: string) => {
    if (!hasMore) return;

    setIsLoading(true);
    const data: any = { page: pageNumber, take: 8 };
    if(searchField) {
      data.search = searchField;
    }
    try {
      const res = await getMatchingList(data);
      const newPosts = res.data.filter((el: any) => el?.requestStatus !== 'ARCHIVED');

      if (pageNumber > 1) {
        setPosts((prevPosts: any) => {
          return [...prevPosts, ...newPosts];
        });
      } else {
        setPosts([...newPosts]);
      }
      setHasMore(newPosts.length > 0);
      setIsSubscriptionActive(true);
    } catch (error: any) {
      setIsSubscriptionActive('active/trialing subscription required' === error?.message ? false : true);
      setResponseError(error.message);
    } finally {
      setIsLoading(false);
      setTriggerFetch(false);
    }
  }, [setResponseError]);

  useEffect(() => {
    getPostsArr(pageNumber, searchText);
  }, [pageNumber, getPostsArr]);

  useEffect(() => {
    if (triggerFetch) {
      getPostsArr(1, searchText);
    }
  }, [triggerFetch]);

  useEffect(() => {
    if (!isLoading) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll]);

  const toInventory = () => {
    navigate('/vendor/settings/profile');
  }

  const renderNoPostinfo = () => {
    if (isLoading) {
      return <Loader />;
    }

    if (!isLoading && responseError) {
      return (
        <Box
          sx={{
            justifyContent: 'center',
            textAlign: 'center',
            color: theme.palette.error.main,
          }}
        >
          {responseError}
        </Box>
      );
    }

    return (
      <>
        {
          !isInventoryExist ?
            <Typography variant="h5">Fill out your&nbsp;
              <b
                style={{ cursor: 'pointer', color: 'rgb(0, 0, 238)', textDecoration: hover ? 'underline' : 'none' }}
                onClick={toInventory}
                onMouseEnter={()=>{
                  setHover(true);
                }}
                onMouseLeave={()=>{
                  setHover(false);
                }}
              >
                inventory description&nbsp;
              </b>
             to start matching with buyers!
            </Typography>
          : searchText ?
            <Typography variant="h5">There are no matches with <i>{searchText}</i>.</Typography>
            :
            <Typography variant="h5">There are no matches.</Typography>
        }
      </>
    );
  };

  return (
    <Box
      sx={{
        mb: '35px',
      }}
    >
      {isLoading ? <Loader /> : null}
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {posts?.length > 0 ? (
          posts.map((el: any, i: any) => {
            if (i === posts.length - 1) {
              return (
                <Grid sx={{
                  display: 'flex'
                }} key={el?.requestedVehicleItemId} item xs={12} md={6} xl={6} ref={lastPostRef}>
                  <VendorPost post={el} />
                </Grid>
              );
            } else {
              return (
                <Grid sx={{
                  display: 'flex'
                }} key={el?.requestedVehicleItemId} item xs={12} md={6} xl={6}>
                  <VendorPost post={el} />
                </Grid>
              );
            }
          })
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: '35px',
              width: '100%',
            }}
          >
            {renderNoPostinfo()}
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default VendorActive;
