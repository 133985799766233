import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { InputText } from './elements/inputText';
import { useForm, SubmitHandler } from "react-hook-form"
import { useResponseError } from './../hooks/useResponseError';
import { useTheme } from '@mui/material/styles';
import { getUserData, updateUserData } from '../service/userService';
import { STORAGE_USER } from '../constants/storage';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";
import { useAuth } from '../hooks/useAuth';

type Inputs = {
  firstName: string,
  lastName: string,
  phoneNumber?: string,
  city: string,
  state: string,
  zip: string,
}

const BuyerProfileSettings = () => {
  const theme = useTheme();
  const { user, setUser } = useAuth();
  const { responseError, setResponseError } = useResponseError();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const getUserObj = async () => {
      try {
        const res = await getUserData({});
        const post = res;
        setUser(res);
        setValue('firstName', post?.firstName, { shouldTouch: true })
        setValue('lastName', post?.lastName, { shouldValidate: true })
        setValue('phoneNumber', post?.phoneNumber, { shouldValidate: true })
        setValue('city', post?.city, { shouldTouch: true })
        trigger('city')
        setValue('state', post?.state, { shouldTouch: true })
        trigger('state')
        setValue('zip', post?.zip, { shouldTouch: true })
        trigger('zip')
      } catch (error: any) {
        console.error('error', error)
      }

    };

    getUserObj();

    return () => { };
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger
  } = useForm<Inputs>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      city: user?.city,
      state: user?.state,
      zip: user?.zip,
    },
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formatData = data;
    if (formatData.phoneNumber === '') {
      delete formatData.phoneNumber
    }

    try {
      const res = await updateUserData({
        id: user.id,
        data: formatData,
      });

      ReactGA.event('updateUserData', 
      {
        userID: user?.id,
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        phoneNumber: user?.phoneNumber,
        city: user?.city,
        state: user?.state,
        zip: user?.zip,
        roles: user?.roles,
      });
      setUser(res)

    } catch (error: any) {
      setResponseError(error.message);
    }
    handleClickOpen();
  }

  return <>
    <Box
      component="form"
      sx={[{
        '& > :not(style)': { my: 0.5, width: '325px' },
        'display': 'flex',
        'flexDirection': 'column',
      },
      (theme) => ({
        [theme.breakpoints.down('sm')]: {
          '& > :not(style)': { my: 0.5, width: '100%' },
        }
      }),
      ]}
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      id="hook-form"
    >
      <InputText
        label="First Name"
        variant="filled"
        inputProps={{ ...register("firstName", { required: true }) }}
        InputLabelProps={{ shrink: !!watch('firstName') }}
        error={!!errors['firstName']}
        helperText={errors['firstName'] ? 'This is required' : ''}
      />
      <InputText
        label="Last Name"
        variant="filled"
        inputProps={{ ...register("lastName", { required: true }) }}
        InputLabelProps={{ shrink: !!watch('lastName') }}
        error={!!errors['lastName']}
        helperText={errors['lastName'] ? 'This is required' : ''}
      />
      <InputText
        label="Email"
        variant="filled"
        inputProps={{
          sx: {
            backgroundColor: '#0000000f',
            color: '#00000071'
          }, disabled: true, readOnly: true
        }}
        defaultValue={user.email}
      />
      <InputText
        label="Phone number (Optional)"
        variant="filled"
        inputProps={{ ...register("phoneNumber") }}
        InputLabelProps={{ shrink: !!watch('phoneNumber') }}
        error={!!errors['phoneNumber']}
        helperText={errors['phoneNumber'] ? 'This is required' : ''}
      />
      <InputText
        label="City"
        variant="filled"
        inputProps={{ ...register("city", { required: true }) }}
        InputLabelProps={{ shrink: !!watch('city') }}
        error={!!errors['city']}
        helperText={errors['city'] ? 'This is required' : ''}
      />
      <InputText
        label="State"
        variant="filled"
        inputProps={{ ...register("state", { required: true }) }}
        InputLabelProps={{ shrink: !!watch('state') }}
        error={!!errors['state']}
        helperText={errors['state'] ? 'This is required' : ''}
      />
      <InputText
        label="Zip"
        variant="filled"
        inputProps={{ ...register("zip", { required: true }) }}
        InputLabelProps={{ shrink: !!watch('zip') }}
        error={!!errors['zip']}
        helperText={errors['zip'] ? 'This is required' : ''}
      />
    </Box>
    <Dialog
      sx={[{
        '.MuiDialog-paper': {
          px: '55px',
          paddingBottom: '35px',
          pt: '20px',
        }
      },
      (theme) => ({
        [theme.breakpoints.down('md')]: {
          '.MuiDialog-paper': {
            px: '15px',
          }
        }
      }),
      ]}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseModal close={handleClose} />
      <DialogTitle sx={{
        fontWeight: '700',
        maxWidth: '300px',
        textAlign: 'center'
      }}>
        {responseError ? "Something went wrong" : `Change has been submitted successfully`}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Box sx={{ textAlign: 'center', color: theme.palette.error.main }}>{responseError}</Box>
      </DialogContent>
      <DialogActions
        sx={{
          maxWidth: '325px',
          justifyContent: 'center',
        }}
      >
        <Button
          sx={{
            padding: '7px 20px',
            borderRadius: '10px',
            width: '100%',
          }}
          variant="contained"
          onClick={handleClose}
          autoFocus
        >
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  </>;
};

export default BuyerProfileSettings;

