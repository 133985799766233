import { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, alpha, styled } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';

type SelectItems = {
  value: string,
  label: string,
}

type SelectProps = {
  label?: string,
  name?: string,
  items: SelectItems[],
  onBlur?: any,
  onChange: any,
  value: any,
}

export default function SelectControl({label, items, name, ...selectProps}: any) {
  const theme = useTheme();
  const iconElement = () => {
    return (
      <div className={'select-icon'}>
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L5.29289 5.29289C5.68342 5.68342 6.31658 5.68342 6.70711 5.29289L11 1" stroke={theme.palette.primary.main} strokeWidth="2"/>
        </svg>
      </div>
    )
  }

    return (
      <Box sx={{ 
          minWidth: 120, 
          paddingTop: '4px', 
          paddingBottom: '4px', 
        }}>
        <FormControl variant="filled" fullWidth>
          {label && <InputLabel sx={{
            left: '9px',
          }} id={`${label}-select-label`}>{label}</InputLabel>}
          <Select
            labelId={`${label}-select-label`}
            id={`${label}-item`}
            name={name}
            label={label}
            IconComponent={iconElement}
            defaultValue={items[0].value}
            {...selectProps}
          >
            {items.map((item: SelectItems, i: number) => {
                return <MenuItem key={item.value + i} value={item.value}>{item.label}</MenuItem>
            })}
          </Select>
        </FormControl>
      </Box>
    );
  }