import { handleApiError } from "./handleApiError";
import instance from './index';

type GetInventory = {
};

type IPost = {
  page?: number,
  take?: number,
  requestStatus?: string,
  fulfillmentStatus?: string,
  orderBy?: string,
  order?: string,
  search?: string,
};

type ICreatePost = {
  name: string,
  shippingType: string,
  requestStatus?: string,
  description: string,
  city: string,
  state: string,
  zip: string,
  pickupRadius: number,
  eventName?: string,
}

type IEditPost = {
  id: string,
  name: string,
  shippingType: string,
  requestStatus?: string,
  description: string,
  city: string,
  state: string,
  zip: string,
  pickupRadius: number,
  eventName?: string,
}

type IExtendPost = {
  id?: string,
}

type IArchivePost = {
  id: string,
  name: string,
  shippingType: string,
  fulfillmentStatus?: string,
  fulfillmentType?: 'DELIVERY' | 'LOCAL_PICKUP' | 'EVENT_PICKUP' ,
  requestStatus?: string,
  description: string,
  city: string,
  state: string,
  zip: string,
  pickupRadius: number,
  eventName?: string | null,
}

type IArchivePostData = {
  name: string,
  shippingType: string,
  fulfillmentStatus?: string,
  fulfillmentType?: 'DELIVERY' | 'LOCAL_PICKUP' | 'EVENT_PICKUP' ,
  requestStatus?: string,
  description: string,
  city: string,
  state: string,
  zip: string,
  pickupRadius: number,
  eventName?: string | null,
}

type IGetPostById = {
  id?: string,
}

type IRemovePostById = {
  id: string,
}

export const getPosts = async ({
  page = 1,
  take = 12,
  requestStatus = 'PUBLISHED',
  fulfillmentStatus,
  orderBy = 'updatedAt',
  order = 'desc',
}: IPost) => {
  try {

    const response = await instance.get(`/requested-vehicle-items/my?page=${page}&take=${take}${requestStatus ? '&filters[requestStatus]=' + requestStatus : ''
      }${fulfillmentStatus ? '&filters[fulfillmentStatus]=' + fulfillmentStatus : ''}${'&sorting[orderBy]=' + orderBy}`
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getAllPosts = async ({
  page = 1,
  take = 12,
  orderBy = 'updatedAt',
  search,
  order = 'desc',
}: IPost) => {
  try {

    const response = await instance.get(`/requested-vehicle-items?page=${page}&take=${take}${'&sorting[orderBy]=' + orderBy}${search ? '&search=' + search : ''}`
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const createPost = async ({
  name,
  shippingType = "SHIP_ANYWHERE",
  requestStatus = "PUBLISHED",
  description,
  city,
  state,
  zip,
  pickupRadius,
  eventName,
}: ICreatePost) => {
  try {
    const data = {
      name,
      shippingType,
      requestStatus,
      description,
      city,
      state,
      zip,
      pickupRadius,
      eventName: eventName ? eventName : null,
    }
    const response = await instance.post(`/requested-vehicle-items/my`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getPostById = async ({
  id,
}: IGetPostById) => {
  try {
    const response = await instance.get(`/requested-vehicle-items/my/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const getGeneralPostById = async ({
  id,
}: IGetPostById) => {
  try {
    const response = await instance.get(`/requested-vehicle-items/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const editPost = async ({
  id,
  name,
  shippingType = "SHIP_ANYWHERE",
  requestStatus = "PUBLISHED",
  description,
  city = "Southfield",
  state = "Michigan",
  zip = "48075",
  pickupRadius,
  eventName,
}: IEditPost) => {
  try {
    const data = {
      name,
      shippingType,
      requestStatus,
      description,
      city,
      state,
      zip,
      pickupRadius,
      eventName: eventName ? eventName : null,
    }
    const response = await instance.patch(`/requested-vehicle-items/my/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const scheduleRepost = async ({
  id,
}: IExtendPost) => {
  try {
    const response = await instance.patch(`/requested-vehicle-items/my/${id}/trigger-schedule-repost`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const extendPost = async ({
  id,
}: IExtendPost) => {
  try {
    const data = {
      requestStatus: "PUBLISHED",
      fulfillmentStatus: "UNFULFILLED",
    }
    const response = await instance.patch(`/requested-vehicle-items/my/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const archivePost = async ({
  id,
  name,
  shippingType,
  fulfillmentStatus = "FULFILLED",
  fulfillmentType,
  description,
  city,
  state,
  zip,
  pickupRadius,
  eventName,
}: IArchivePost) => {
  try {
    const data: IArchivePostData = {
      name,
      shippingType,
      description,
      city,
      state,
      zip,
      pickupRadius,
      requestStatus: "ARCHIVED",
      fulfillmentStatus,
      eventName: eventName ? eventName : null,
    }

    if(fulfillmentType) {
      data.fulfillmentType = fulfillmentType;
    }
    const response = await instance.patch(`/requested-vehicle-items/my/${id}`, data);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};

export const removePostById = async ({
  id,
}: IRemovePostById) => {
  try {
    const response = await instance.delete(`/requested-vehicle-items/my/${id}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
};