import { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Link as MaterialLink,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { blogList } from '../data/mock-blog-list';
import { InputText } from './elements/inputText';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import BuyerPost from './buyerPost';
import SelectControl from './elements/SelectControl';
import { useTheme } from '@mui/material/styles';
import { postInventory } from '../service/inventoryService';
import { createPost } from '../service/postService';
import { useResponseError } from './../hooks/useResponseError';
import { STORAGE_USER } from '../constants/storage';
import { TUserProfile } from '../types/types';
import { CloseModal } from './modal/CloseModal';
import ReactGA from "react-ga4";
import { htmlToSlate, slateToHtml } from '@slate-serializers/html';
import RichText from './elements/Richtext';

type Inputs = {
  name: string,
  description: string,
  city: string,
  state: string,
  zip: string,
  shippingType: string,
  pickupRadius: number,
  eventName?: string,
}

const BuyerCreate = () => {
  const navigate = useNavigate();
  const [postId, setPostId] = useState('');
  const [radius, setRadius] = useState('10');
  const [editorString, setEditorString] = useState('');
  const [shippingType, setShippingType] = useState('EVENT_PICKUP');
  const [editorLabelSmall, setEditorLabelSmall] = useState(false);
  const [editorFocused, setEditorFocused] = useState(false);
  const theme = useTheme();
  const { responseError, setResponseError } = useResponseError(25000);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<TUserProfile | null>(JSON.parse(localStorage.getItem(STORAGE_USER) || '') || null);
  const [editorValue, setEditorValue] = useState<any>([
    {
      type: 'paragraph',
      children: [
        { text: '' },
      ],
    },
  ]);

  const handleClose = (isSuccess: boolean) => {
    setOpen(false);
    if (isSuccess) {
      navigate(`/buyer`);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: '',
      description: '',
      city: user?.city || '',
      state: user?.state || '',
      zip: user?.zip || '',
      shippingType: 'EVENT_PICKUP',
      pickupRadius: 10,
      eventName: ''
    },
    mode: "onChange",
  });

  useEffect(() => {
    let html = slateToHtml(editorValue);
    if (html !== '<p></p>' && html !== '') {
      setEditorLabelSmall(true);
    } else {
      setEditorLabelSmall(false);
    }
    setEditorString(html);
  }, [editorValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'shippingType') {
        setShippingType(String(value.shippingType));
      }
    }
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onBlurEditor = () => {
    let html = slateToHtml(editorValue);
    if (html !== '<p></p>' && html !== '') {
      setEditorLabelSmall(true);
    } else {
      setEditorLabelSmall(false);
    }
    setEditorFocused(false);
  }
  const onFocusEditor = () => {
    setEditorLabelSmall(true);
    setEditorFocused(true);
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);

    const formatData = data;
    formatData.description = editorString;
    formatData.pickupRadius = Number(formatData.pickupRadius);
    if (formatData.shippingType === 'SHIP_ANYWHERE') {
      formatData.state = '';
      formatData.zip = '';
      formatData.city = '';
    }

    try {
      const res = await createPost({
        ...formatData,
      });

      setPostId(res.id);
      ReactGA.event({
        category: 'Buyer',
        action: 'createPost',
        label: 'Create Post',
        value: res.id
      });
      handleClickOpen();
    } catch (error: any) {
      handleClickOpen();
      setResponseError(error.message);
    }

    setLoading(false);
  }

  return (
    <Box sx={{
      pt: '60px',
      pb: '11px',
      mb: '35px'
    }}>
      <Box sx={{
        'display': 'flex',
        'justifyContent': 'space-between',
        marginBottom: '25px'
      }}>
        <Box>
          <Breadcrumbs sx={{
            marginBottom: '25px',
            display: 'flex',
            alignItems: 'center',
            '.MuiBreadcrumbs-li': {
              display: 'flex',
              alignItems: 'center',
              fontSize: '13px',
            },
            '.MuiBreadcrumbs-separator': {
              mx: '4px',
              fontSize: '13px',
            }
          }} aria-label="breadcrumb">
            <MaterialLink sx={{
              fontSize: '13px',
            }} underline="hover" color="inherit" to="/buyer" component={Link as any}>
              Home
            </MaterialLink>
            <Typography sx={{
              fontSize: '13px',
            }} color="text.primary">New request</Typography>
          </Breadcrumbs>
          <Typography
            variant="h2"
            style={
              {
                color: '#000',
                fontWeight: 400,
                fontSize: '34px',
              }
            }>
            New request
          </Typography>
        </Box>
        <Box sx={[
          {
            'display': 'flex',
            'alignItems': 'center',
            marginBottom: '35px',
          },
          (theme) => ({
            [theme.breakpoints.down('md')]: {
              display: 'none',
            }
          }),
        ]}>
          <Box
            sx={{
              'ml': 'auto',
              p: '10px',
              backgroundColor: 'white',
              borderRadius: '10px',
              boxShadow: '0px 10px 10px rgba(0, 137, 134, 0.10)',
            }}>
            <Button type="submit" form="hook-form" sx={{ borderRadius: '10px' }} variant="contained">Publish</Button>
          </Box>
        </Box>
      </Box>
      <Box
        component="form"
        sx={[{
          '& > :not(style)': { my: 0.5, width: '100%', maxWidth: '850px' },
          'display': 'flex',
          'flexDirection': 'column',
        },
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            '& > :not(style)': { maxWidth: '100%', display: 'flex', flexDirection: 'column' },
          }
        }),
        ]}
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
        id="hook-form"
      >
        <InputText
          label="What are you looking for?"
          variant="filled"
          inputProps={{ ...register("name", { required: true }) }}
          error={!!errors['name']}
          helperText={errors['name'] ? 'This is required' : ''}
        />
        <Controller
          control={control}
          name='shippingType'
          render={({ field: { onChange, onBlur, value } }) => (
            <SelectControl
              label={'Delivery'}
              items={
                [
                  { value: 'EVENT_PICKUP', label: 'Event pickup' },
                  { value: 'LOCAL_PICKUP', label: 'Local pickup' },
                  { value: 'SHIP_ANYWHERE', label: 'Ship from anywhere' },
                ]
              }
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        {shippingType === "EVENT_PICKUP" && <InputText
          label="Event Name (Optional)"
          variant="filled"
          inputProps={{ ...register("eventName") }}
        />}
        {(shippingType === "LOCAL_PICKUP" || shippingType === "EVENT_PICKUP") &&
          <Box sx={[{
            'display': 'flex',
            'alignItems': 'center',
            '.MuiFormControl-root': {
              maxWidth: '150px',
              width: '100%',
              mr: '10px'
            }
          },
          (theme) => ({
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
              '.MuiFormControl-root': {
                maxWidth: '100%'
              }
            }
          }),
          ]}>
            <Box sx={{
              'display': 'flex',
              alignItems: 'center',
              width: '100%'
            }}>
              <Box sx={{ display: 'flex', marginRight: '21px' }}>
                <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M13.4038 18.9297C16.1469 14.7418 18 11.9126 18 8.66667C18 3.8802 13.9706 0 9 0C4.02944 0 0 3.8802 0 8.66667C0 11.9126 1.85313 14.7418 4.59619 18.9297C5.65598 20.5477 6.84861 22.3685 8.11852 24.4945C8.5146 25.1575 9.4854 25.1575 9.88147 24.4945C11.1514 22.3685 12.344 20.5477 13.4038 18.9297ZM9 13C11.7614 13 14 10.9853 14 8.5C14 6.01472 11.7614 4 9 4C6.23858 4 4 6.01472 4 8.5C4 10.9853 6.23858 13 9 13Z" fill="#CCCCCC" />
                </svg>
              </Box>
              <InputText
                label="City"
                variant="filled"
                inputProps={{ ...register("city", { required: true }) }}
                error={!!errors['city']}
                helperText={errors['city'] ? 'This is required' : ''}
              />
              <InputText
                sx={{
                  mr: 'auto',
                }}
                label="State"
                variant="filled"
                inputProps={{ ...register("state", { required: true }) }}
                error={!!errors['state']}
                helperText={errors['state'] ? 'This is required' : ''}
              />
              <InputText
                label="Zip"
                variant="filled"
                inputProps={{ ...register("zip", { required: true }) }}
                error={!!errors['zip']}
                helperText={errors['zip'] ? 'This is required' : ''}
              />
            </Box>
            <Box sx={[{
              'display': 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              ml: 'auto',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                ml: 0
              }
            }),
            ]}>
              <Controller
                control={control}
                name='pickupRadius'
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectControl
                    label={'Radius'}
                    items={
                      [
                        { value: '10', label: '10 miles' },
                        { value: '20', label: '20 miles' },
                        { value: '50', label: '50 miles' },
                        { value: '100', label: '100 miles' },
                        { value: '250', label: '250 miles' },
                      ]
                    }
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </Box>
          </Box>
        }
        <Box sx={[{
          '& > .MuiTypography-root': {
            mt: '16px',
            mb: editorFocused ? '30px' : '16px',
            pl: '20px',
            lineHeight: '1.6',
          },
        },
        (theme) => ({
          [theme.breakpoints.down('sm')]: {
            '& > .MuiTypography-root': {
              mb: editorFocused ? '70px' : '16px',
            }
          }
        })]}>
          <Typography
            sx={[{
              color: '#666666',
              maxWidth: '740px',
              width: '100%',
            },
            (theme) => ({
              [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
                width: 'auto !important'
              }
            }),
            ]}
            variant={'body1'}>
            Tell vendors what you are looking for. Be as vague as you want or down to the part number to help find your needle in the haystack.
          </Typography>
          <Box sx={{
            '&': {
              position: 'relative',
              py: 0.5
            },
            '& .field-wrapper': {
              backgroundColor: 'white',
              borderRadius: '10px',
              border: '1px solid #CCCCCC',
              paddingTop: '25px',
              position: 'relative',
              transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
            },
            '& .field-wrapper-focused': {
              borderColor: theme.palette.primary.main,
            },
            '& .field-editor': {
              backgroundColor: 'white',
              padding: '0px 20px 8px',
              borderRadius: '10px',
              minHeight: '400px',
            },
            '& .toolbar-box': {
              position: 'absolute',
              zIndex: '1',
              visibility: editorFocused ? 'visible' : 'hidden',
              transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
            },
            '& .field-title': {
              position: 'absolute',
              top: '10px',
              left: '20px',
              zIndex: '1',
              color: '#999999',
              fontSize: '21px',
              lineHeight: '1.6',
            },
            '& .field-wrapper-focused .field-title': {
              color: theme.palette.primary.main,
            },
            '& .field-title.field-title--small': {
              fontSize: '12px',
              top: '9px',
              left: '22px',
            },
            '& .field-title--placeholder': {
              top: '24px',
              left: '21px',
              pointerEvents: 'none',
            }
          }}>
            <div className={`field-title ${editorFocused ? 'field-title--focused' : ''} field-title--small`}>
              Description (Optional)
            </div>
            <div className={`field-title ${editorFocused ? 'field-title--focused' : ''} field-title--placeholder`}>
              {!editorLabelSmall && !editorFocused && (editorString === '<p></p>' || editorString === '') ?
                'Example: In search of 1969 Chevy Camaro driver side headlight bucket part# 123456' :
                null
              }
            </div>
            <RichText
              onFocusEditor={onFocusEditor}
              onBlurEditor={onBlurEditor}
              value={editorValue}
              setValue={setEditorValue}
              isReady={true}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={[
        {
          display: 'none'
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            display: 'block',
            maxWidth: '335px',
            margin: '0 auto',
            marginTop: '20px'
          }
        }),
      ]}>
        <Button
          disabled={loading ? true : false}
          type="submit"
          form="hook-form"
          sx={{ borderRadius: '10px', width: '100%' }}
          variant="contained">
          Publish
        </Button>
      </Box>
      <Dialog
        sx={[{
          '.MuiDialog-paper': {
            pt: '20px',
            px: '55px',
            paddingBottom: '35px'
          }
        },
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            '.MuiDialog-paper': {
              px: '15px',
            }
          }
        }),
        ]}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CloseModal close={handleClose} />
        <DialogTitle sx={{
          fontWeight: '700',
          maxWidth: '300px',
          textAlign: 'center'
        }}>
          {responseError ? "Something went wrong" : `Request has been created successfully`}
        </DialogTitle>
        <DialogContent sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Box sx={{ textAlign: 'center', color: theme.palette.error.main }}>{responseError}</Box>
        </DialogContent>
        <DialogActions
          sx={{
            maxWidth: '325px',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              padding: '7px 20px',
              borderRadius: '10px',
              width: '100%',
            }}
            variant="contained"
            onClick={() => handleClose(responseError ? false : true)}
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </Box >);
};

export default BuyerCreate;