import { talkJsId } from "../constants";
import { handleApiError } from "./handleApiError";
import instance, { baseURL } from './index';

export const getSignature = async () => {
  try {
    const response = await instance.get(`/chat/signature/my`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}

export const getConversationId = async (itemId: string) => {
  try {
    const response = await instance.get(`/chat/conversation/${itemId}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
  }
}